import axios from 'axios';

import { baseURL } from '../../app.config';

export const api = axios.create({
  baseURL: baseURL,
  headers: {
    'content-type': 'application/json',
  },
  // withCredentials: true,
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response && error?.response?.status === 401) {
      const params = new URLSearchParams(window.location.search);
      localStorage.removeItem('token');
      if (!window.location.pathname.includes("/bittensor_testing"))
      window.location.pathname = `/sign-in?${params.toString()}`;
    }
    return Promise.reject(error);
  }
);

export default api;

/* ====================  Auth API's ==================== */

// Login
export const login = data => api.post('/api/sigin', data);

// Register user
export const signup = data => api.post('/api/signup', data);

// Get User Details
export const getUserDetails = data => api.get('/api/user', data);

// Update User
export const updateUser = data => api.put('/api/user', data);

// Update Password
export const updatePassword = password =>
  api.post('/api/reset-password', password);

// Delete User
export const deleteUserAccount = () => api.delete('/api/user');

// Subscribe User
export const subscribeUser = data => api.post('/api/stripe', data);

// UnSubscribe User
export const unSubscribeUser = () => api.post('/api/unsubscribe');

// UnSubscribe From mail
export const unSubscribeMails = () => api.post('/api/unsubscribe-mails');

// UnSubscribe From mail status
export const unSubscribeMailsStatus = () =>
  api.post('/api/unsubscribe-mails-status');

// Predict
export const predictMessage = (data, config) =>
  api.post('/api/predict', data, config);

// create conversation
export const createConvesation = data => api.post('/api/conversations', data);

// get conversations
export const getConversations = query => api.get(`/api/conversations${query}`);
// get conversation messages
export const getConversationMessage = conversation_id =>
  api.get(`/api/messages?conversation_id=${conversation_id}`);

// delecte conversation

export const deleteConversation = conversation_id =>
  api.delete(`api/conversations?conversation_id=${conversation_id}`);

// set http only cookie

export const setCookie = () => api.get('/api/setcookie');

// get stripe url

export const getStripeUrl = data => api.post('/api/stripe', data);

// upgrade userplan

export const upgradePlan = data => api.patch('/api/plan', data);

// get userplan

export const getPlan = () => api.get('/api/plan');

// Pause Subscription

export const subscriptionPause = () => api.post('/api/pause-subscription');

// Resume Subscription

export const subscriptionResume = () => api.post('/api/resume-subscription');

// referral

export const generateReferralCode = () =>
  api.get('/api/generate-referral-code');

// text to image
export const convertTextToImage = (data, config) =>
  api.post('/api/textToImage', data, config);

// tags and messages

export const chipMessages = () => api.get('/api/getChips');

// check for duplicate email

export const checkEmail = email => api.post('/api/emailexists', { email });

// get remaining prompts

export const getRemainingPrompts = (data, config) =>
  api.post('/api/remainingPrompts', data, config);

// get ramiaining referral bonus

export const remainingReferralBonus = () =>
  api.post('/api/remainingReferralBonus');

// forgot password email

export const forgotPassEmail = data => api.post('/api/forgot', data);

// Update password otp

export const updatePassOtp = data => api.post('/api/updatepassword', data);

// generate OTP (email verification)

export const generateOTP = email => api.post('/api/generateOtp', { email });

// verify OTP (email verification)

export const verifyOTP = otp => api.post('/api/verifyOtp', { otp });

// stripe api

export const makePayment = data => api.post('/api/stripe', data);

// add data to stripe

export const stripeRecord = data => api.post('/api/subscribe-stripe', data);

// cancel user subscription

export const cancelSubscription = data =>
  api.patch('analytics/api/cancel/user/subscription', data);

// card details

export const getCardDetails = () => api.get('/api/update/my-card/details');

// update card details

export const updateCardDetails = data =>
  api.patch('/api/update/my-card/details', data);

export const userInvoices = () => api.get('api/user/invoices');

// update user profile

export const updateProfileImage = data =>
  api.patch('api/user/profile-picture', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

// notifications

export const allNotifications = () => api.get('api/user/notifications');

export const markAllNotifications = () =>
  api.patch('api/user/notifications', {});

// sign up with google

export const signUpWithGoogle = data => api.post('api/signup-social', data);

// social login in

export const socialLogin = data => api.post('api/sigin-social', data);

// get message by id

export const getMessageById = id =>
  api.post('api/prompt/shared-prompt', {
    prompt_id: id,
  });

// get conversation public

export const getConversationByIdPublic = id =>
  api.post('api/prompt/shared-prompt', {
    convo_id: id,
  });

// update chat label

export const updateChatLable = data =>
  api.patch('api/conversation/edit-title', data);

// favourite chat

export const favouriteChat = data =>
  api.patch('api/conversation/add-favourite', data);

// feedback

export const feedbackOnMessage = data => api.patch('api/prompt/action', data);

// image generation templates

export const imageGenerationTemplates = () =>
  api.get('api/image-gen/get-templates');

// image generation filters

export const imageGenerationFilters = () =>
  api.get('api/image-gen/get-filters');

export const images_history = (image_model, filter) =>
  api.get(`api/images_history?image_model=${image_model}&filter=${filter}`);

export const get_image = (prompt_id) => 
  api.get(`api/get_image/?prompt_id=${prompt_id}`)

export const delete_image = (prompt_id) => 
  api.post(`api/delete_image/?prompt_id=${prompt_id}`)

// image generation generate image

export const generateNewImage = data =>
  api.post('api/image-gen/generate-image-v2', data);
//api.post('api/image-gen/generate-image', data);

// coupon verification
export const verifyDiscountCoupon = code =>
  api.post('api/verify_coupon', {
    coupon_code: code,
  });

// fetches user, remaining prompts and plan data at once

export const userDashboardData = () => api.get('/api/user/dashboard');

// create gift user

export const createGiftUser = data => api.post('api/create_gift_user', data);

// klaviyo gift user

export const klaviyoGiftUser = data => api.post('api/klaviyo_gift_user', data);

// location data

export const getLocationData = () => api.get('/api/get_ip_location');

// Document Apis

// save a document
export const saveDocument = data => api.post('/api/TextDocumentAPI/', data);

// get all documents

export const getAllDocuments = () => api.get('/api/TextDocumentAPI/');

// update a document

export const updateDocument = data => api.put('/api/TextDocumentAPI/', data);

// delete a document

export const deleteDocument = id =>
  api.delete(`/api/TextDocumentAPI/?id=${id}`);

//  ask Ai operations

export const askAiOperations = data => api.post('/api/DocumentAIPopup/', data);

export const documentImageGeneration = data =>
  api.post('/api/DocumentAIPopup/', data);

export const convertBase64StringFromURl = image_url =>
  api.post('/api/ConvertBase64/', { url_file: image_url });

export const convertDocToHtml = (data, config) =>
  api.post('/api/WordToHTMLAPIView/', data, config);

// discount eligible

export const discountEligible = email =>
  api.get(`api/discount_eligible/?email=${email}`);

// text to Docx

export const convertTODocx = content =>
  api.post('api/HTMLToWordAPIView/', { content });

export const getUserImages = param =>
  api.get(`api/image-gen/get_user_images&filter=${param}`)

export const imageGenPut = prompt_id =>
  api.put(`api/image-gen/images_actions/${prompt_id}`)
  
export const imageGenDelete = prompt_id =>
  api.delete(`api/image-gen/images_actions/${prompt_id}`)

export const bittensor_test = (payload, config) => 
  api.post(`api/bittensor_test/`, payload, config)

export const bittensor_test_stars = (payload) =>
  api.post(`api/bittensor_test/`, payload)