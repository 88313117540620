import styled from 'styled-components';

export const Container = styled.div`
  & .divider {
    border: 1px solid #5c616d;
    width: 95%;
    margin: auto;
  }
  .subscription__plan-inner {
    background: var(--lightgrey);
    border-radius: 15px;
    min-height: 364px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 15px;
    height: 100%;
    @media only screen and (max-width: 560px) {
      margin-bottom: 15px;
    }
  }

  .subscription__plan-inner .subs-inner svg {
    flex-shrink: 0;
  }

  .subscription__plan-inner .heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: var(--primary);
    opacity: 1;
    padding-top: 25px;
    margin-bottom: 0;
    text-align: center;
  }

  .subscription__plan-inner .text {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    color: var(--primary);
    text-align: center;
    margin-block: 12px 15px;
    &.subtitle {
      opacity: 1;
      font-size: 12px;
      font-weight: 400;
      margin: 0px;
      line-height: 18px;
      margin-top: 3px;
    }
    & .text-small {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
    }
  }
  .subscription__plan-inner .subs__details-grid {
    margin-top: 22px !important;
  }
  .subscription__plan-inner .subs-inner {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-left: 15px;
    margin-bottom: 15px;
  }
  .subscription__plan-inner .subs-inner p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--primary);
    opacity: 1;
    margin-bottom: 0;
    padding-right: 20px;
    text-align: left;
  }
  .subscription__plan-inner .btn-head {
    margin: 29px auto 0 auto;
    width: 93%;
    /* width: 293px; */
    @media only screen and (max-width: 560px) {
      width: 90%;
    }
  }

  .subscription__plan-inner .plan-btn {
    background: var(--darkgrey);
    border-radius: 7px;
    width: 100%;
    height: 50px;
    text-align: center;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--primary);
    opacity: 1;
    &.active {
      color: rgba(255, 255, 255, 0.3) !important;
    }
    &.active-year {
      color: var(--red) !important;
    }
    &.white {
      background: var(--primary);
      color: var(--darkgrey);
      opacity: 1;
    }
    &.white.active {
      background: rgba(255, 255, 255, 0.3);
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  .subscription__plan-inner.red {
    background: var(--red) !important;
    opacity: 1 !important;
  }
  .subscription__plan-inner.red .plan-btn {
    background: var(--primary) !important;
    color: var(--red) !important;
    opacity: 1 !important;
  }
`;
