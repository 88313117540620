/* eslint-disable react-hooks/exhaustive-deps */
import assets from 'assets';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { config, bittensor_test_Model } from 'container/data/models';
import { useSelector, useDispatch } from 'react-redux';
// import { modes } from 'app.config';
import ClickAwayListener from 'react-click-away-listener';
import { modes } from 'app.config';

const ApplicationDropdown = ({ isMobile }) => {
  const model = useSelector(state => state.chat.model);
  const dispatch = useDispatch();
  const { mode, bittensor } = useSelector(state => state.utils);
  const loading = useSelector(state => state.chat.responseLoading);
  const imageLoading = useSelector(state => state.imageGeneration.loading);

  const [open, setOpen] = useState(false);

  const handleChangeModel = model => {
    // window.open(`${siteURL}/${model?.id}`);
    dispatch.chat.setModel(model);
    if (model.value === 'picasso') {
      dispatch.utils.setOpenControls(true);
      // dispatch.utils.setMode(modes.image);
    } else if (model.id === 'DLLM') {
      // dispatch.utils.setMode(modes.document);
    } else {
      // dispatch.utils.setMode(modes.chat);
    }
    if (!loading && !imageLoading) {
      dispatch.chat.setSelectedConversation(null);
      dispatch.chat.setSelectedImage(null);
      dispatch.chat.setMessages([]);
      // dispatch.chat.getAllConversations(`?model=${model.value}`);
    }
    dispatch.utils.setOpenMobileRightMenu(false);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (bittensor) {
      dispatch.chat.setModel(bittensor_test_Model[0]);
    }
  }, [bittensor]);

  const configData = bittensor ? bittensor_test_Model : config;
  return (
    <Container>
      <div
        onClick={() => {
          if (mode === modes.chat) setOpen(true);
        }}
        className={`dropdown-value ${isMobile && ' mobile-dropdown-value'}`}
      >
        <div className='flex gap-10 align-items-center'>
          {!bittensor && (
            <img
              src={configData.find(x => model.label === x.label)?.image}
              alt=''
              width={24}
              height={24}
            />
          )}
          <span className='name'>{model.label}</span>
          {/* <div className='dot'> */}
          {!isMobile && (
            <svg
              width='10'
              height='10'
              viewBox='0 0 10 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M5.00016 0.833496C2.70433 0.833496 0.833496 2.70433 0.833496 5.00016C0.833496 7.296 2.70433 9.16683 5.00016 9.16683C7.296 9.16683 9.16683 7.296 9.16683 5.00016C9.16683 2.70433 7.296 0.833496 5.00016 0.833496ZM4.68766 3.3335C4.68766 3.16266 4.82933 3.021 5.00016 3.021C5.171 3.021 5.31266 3.16266 5.31266 3.3335V5.41683C5.31266 5.58766 5.171 5.72933 5.00016 5.72933C4.82933 5.72933 4.68766 5.58766 4.68766 5.41683V3.3335ZM5.3835 6.82516C5.36266 6.87933 5.3335 6.921 5.296 6.96266C5.25433 7.00016 5.2085 7.02933 5.1585 7.05016C5.1085 7.071 5.05433 7.0835 5.00016 7.0835C4.946 7.0835 4.89183 7.071 4.84183 7.05016C4.79183 7.02933 4.746 7.00016 4.70433 6.96266C4.66683 6.921 4.63766 6.87933 4.61683 6.82516C4.596 6.77516 4.5835 6.721 4.5835 6.66683C4.5835 6.61266 4.596 6.5585 4.61683 6.5085C4.63766 6.4585 4.66683 6.41266 4.70433 6.371C4.746 6.3335 4.79183 6.30433 4.84183 6.2835C4.94183 6.24183 5.0585 6.24183 5.1585 6.2835C5.2085 6.30433 5.25433 6.3335 5.296 6.371C5.3335 6.41266 5.36266 6.4585 5.3835 6.5085C5.40433 6.5585 5.41683 6.61266 5.41683 6.66683C5.41683 6.721 5.40433 6.77516 5.3835 6.82516Z'
                fill='#D9DAE0'
              />
            </svg>
          )}

          {/* </div> */}
        </div>
        <div>
          <img
            style={{ transform: open ? 'rotate(180deg)' : '' }}
            src={assets.dropdownArrow}
            alt=''
          />
        </div>
      </div>

      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className='dropdown-options'>
            {React.Children.toArray(
              configData
                .filter(x => x.label !== 'Stable Diffusion')
                .map(item => (
                  <div
                    onClick={() => {
                      if (!loading && !imageLoading) {
                        if (item?.disabled) {
                          return;
                        }
                        handleChangeModel(item);
                      }
                    }}
                    className={`option ${
                      model.label === item.label ? 'active' : ''
                    } ${item.disabled && 'disabled'}`}
                  >
                    {!bittensor && (
                      <img
                        width={24}
                        height={24.8}
                        style={{ borderRadius: '5px' }}
                        src={item.image}
                        alt=''
                      />
                    )}
                    <span className='name'>{item.label}</span>
                    <span
                      style={{
                        fontSize: '12px',
                        fontWeight: '400',
                        opacity: '0.5',
                        lineHeight: 'inherit',
                        color: 'var(--primary)',
                      }}
                    >
                      {item.desc}
                    </span>
                  </div>
                ))
            )}
          </div>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default ApplicationDropdown;

const Container = styled.div`
  position: relative;
  & .gap-10 {
    gap: 10px;
  }

  & .mobile-dropdown-value {
    border: 1px solid #3c3e45 !important;
    background-color: #35363a !important;
    border-radius: 8px;
    display: flex;
    gap: 7px;
    align-items: center;
    color: white;
    height: 100% !important;
    padding: 10px !important;
  }
  & .dropdown-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 54px;
    border-bottom: 1px solid #4d4f56;
    padding: 14px;
    cursor: pointer;

    & .name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      font-family: Poppins;
    }

    & .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #d9dae0;
    }
  }

  & .dropdown-options {
    position: fixed;
    z-index: 9999;
    width: max-content;
    border-radius: 8px;
    border: 1px solid #4d4f56;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #35363a;
    max-height: 400px;
    overflow: auto;

    & .option {
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      padding: 8px 10px;
      cursor: pointer;

      & .name {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary);
      }
    }

    & .active {
      background-color: #4d4f56;
    }

    & .disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  @media only screen and (max-width: 1000px) {
    & .dropdown-options {
      & .name {
        font-family: Poppins !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px !important;
      }
    }
  }
`;
