/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SignIn from '../SignIn/SignIn';
import Home from '../Home/Home';

const Bittensor = () => {
    const dispatch = useDispatch();
    const { isBittensorLogin } = useSelector(state => state.utils);

    useEffect(() => {
        dispatch.utils.setBittensor(true);
        dispatch.utils.setIsBittensorLogin(true);
    }, [])
    
  return (
      <>
          {
              isBittensorLogin
                  ? (<SignIn bittensor={true} />)
                  : (<Home />)
          }
          
      </>
  )
}

export default Bittensor