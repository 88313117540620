/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect, useRef, useContext } from 'react';
//
import './Home.css';
//
import Content from './components/Content/Content';
// import { STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import StripeFailure from 'container/Dialogs/StripeFailure/StripeFailure';
import StripeSuccess from 'container/Dialogs/StripeSuccess/StripeSuccess';
import { useSearchParams } from 'react-router-dom';
// import SIgnUp from 'container/Dialogs/SignUp/SIgnUp';
// import Subscription from 'container/Dialogs/Subscription/Subscription';
import classNames from 'classnames';
import UpgradeBanner from 'container/components/UpgradeBanner/UpgradeBanner';
import ShareDialog from 'container/Dialogs/ShareDialog/ShareDialog';
import ImageGeneration from './components/ImageGeneration/ImageGeneration';

import AiImagesDetailsDialog from '../../Dialogs/AiImagesDetailDialog/ImageDetailDialog';
// import { tutorialSteps } from 'container/data/tutorialsConfig';
import styled from 'styled-components';
import { abandon5, abandon7, modes } from 'app.config';
import Loader from 'container/Dialogs/Loader/Loader';
import DocumentSidebar from './components/DocumentSidebar/DocumentSidebar';
import DocumentView from './components/DocumentView/DocumentView';
import Topbar from './components/Topbar/Topbar';
import MiniSidebar from './components/MiniSidebar/MiniSidebar';
import NewSidebar from './components/MiniSidebar/NewSidebar/NewSidebar';
import useMediaQuery from 'container/hooks/useMediaQuery';
import MobileSidebar from './components/MiniSidebar/NewSidebar/Components/MobileSidebar/MobileSidebar';
// import { abandon5, abandon7 } from 'app.config';
// import { welcome_discount_code } from 'app.config';
// import CountryBanner from 'container/components/CountryBanner/CountryBanner';
// import SalesBanner from 'container/components/SalesBanner/SalesBanner';
// import { bannerText } from 'app.config';
import * as S from './components/Content/Styles';
import assets from 'assets';
import ApplicationDropdown from './components/MiniSidebar/NewSidebar/Components/ApplicationDropdown/ApplicationDropdown';
import { EditorContext } from 'container/context/editorContext';
import { getConversationMessage } from 'container/api';
import SaveDocument from 'container/Dialogs/SaveDocument/SaveDocument';
import TopBanner from '../TopBanner/TopBanner';

function Home() {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery('(max-width: 1000px)');
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const docLoading = useSelector(state => state.documents.loading);
  // const [, setParams] = useSearchParams(window.location.search);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [params, setParams] = useSearchParams();
  const id = params.get('id')?.split('?')[0] || '';
  const plan_id = params.get('plan_id') || '';
  const signup = params.get('signup');
  const cancel = params.get('cancel') || '';
  const discountCode = params.get('discount_code') || '';
  const buyPlan = params.get('buy_plan') || '';
  const { discountUser, openOutliner } = useSelector(
    state => state.utils
  );
  // const model = useSelector(state => state.chat.model);
  const isRightSideBarOpen = useSelector(
    state => state.utils.isRightSideBarOpen
  );

  const { modalData, model, filter } = useSelector(state => state.imageGeneration);
  const editor = useContext(EditorContext);
  const { selected } = useSelector(state => state.documents);
  // const user = useSelector(state => state.auth.user);
  // const { countryBanner } = useSelector(state => state.location);
  const { remainingPrompts, mobileScreen, selectedConversation, messages } = useSelector(state => state.chat);
  // const openAnalysis = useSelector(state => state.utils.openAnalysis);
  const sendGifts = params.get('send_gifts') || '';
  const { mode, loader, topBanner, bittensor } = useSelector(state => state.utils);
  const planData = useSelector(state => state.subscription.planData);
  const userData = useSelector(state => state.auth.user);
  const [stripeSuccess, setStripeSuccess] = useState(false);
  const [stripeFailoure, setStripeFailure] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [saveDoc, setSaveDoc] = useState(false);
  const [chat, setChat] = useState([])
  // const [showTut, setShowTut] = useState(false);

  const rightSideBarRef = useRef(null);

  // const handleCallback = data => {
  //   const { status } = data;
  //   if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
  //     localStorage.setItem('skipTut', 'true');
  //   }
  // };

  // Get User Details
  useEffect(() => {
    if (token) {
      dispatch.auth.handleGetUser();
    }
    if (signup) {
      setSignUpModal(true);
    }
    dispatch.chips.handleGetChips();
    dispatch.imageGeneration.getTemplates();
    dispatch.imageGeneration.getFilters();
    dispatch.documents.getDocuments();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch.imageGeneration.get_image_history({ model, filter });
  }, [model, filter])

  useEffect(() => {
    setChat(messages.filter(x => x.response_source === 3))
  }, [messages])



  useEffect(() => {
    if (discountUser) {
      // setParams({ discount_code: welcome_discount_code });
    } else {
      // setParams({});
    }
    // eslint-disable-next-line
  }, [discountUser]);

  useEffect(() => {
    if (plan_id && id) {
      setStripeSuccess(true);
    }
  }, [plan_id, id]);

  useEffect(() => {
    if (cancel) {
      setStripeFailure(true);
    }
  }, [cancel]);

  useEffect(() => {
    if (discountCode) {
      if (token) {
        dispatch.utils.setSubscriptionModal(true);
      } else {
        if (!signup) {
          navigate('/sign-in?buy_plan=true');
        }
      }
    }
    // eslint-disable-next-line
  }, [discountCode, token]);

  useEffect(() => {
    if (buyPlan) {
      dispatch.utils.setSubscriptionModal(true);
    }
    // eslint-disable-next-line
  }, [buyPlan]);

  // useEffect(() => {
  //   if (localStorage.getItem('skipTut') === 'true') {
  //     setShowTut(false);
  //   } else {
  //     setShowTut(true);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (remainingPrompts !== 0) {
      if (discountCode === abandon5 || discountCode === abandon7) {
        setParams({});
        dispatch.utils.setSubscriptionModal(false);
      }
    }
    // eslint-disable-next-line
  }, [remainingPrompts]);

  useEffect(() => {
    if (sendGifts) {
      navigate('/settings/gifts');
    }
    // eslint-disable-next-line
  }, [sendGifts]);

  const handleClick = (name) => {
    if (!selected && editor.getText().length > 0) {
      setSaveDoc(true);
    } else {
      dispatch.utils.setMode(modes[name])
      // dispatch.chat.setIsShowMobileSidebar(false)
      dispatch.chat.setMobileScreen('chat')
    }
  }

  const handleSwitchDocument = async () => {
    try {
      dispatch.documents.setLoading(true);
      const id = selectedConversation?.id;
      const { data } = await getConversationMessage(id);
      const messages = data.payload.map(msg => msg.response);
      dispatch.documents.setSelected(null);
      dispatch.utils.setMode(modes.document);
      dispatch.utils.setOpenOutliner(false);
      dispatch.chat.setMobileScreen('chat')
      let contentStr = '';
      messages.forEach((item, index) => {
        const messageStrings = item.split('\n');

        const allString = messageStrings?.map((str, index) => {
          if (str.length > 0) {
            return `<p>${str}</p>`;
          } else if (index !== messageStrings.length - 1 && str.length > 1) {
            return `<br />`;
          } else {
            return '';
          }
        });
        contentStr += allString?.join('');
      });
      editor.commands.setContent(contentStr);
    } catch (err) {
      console.log(err.message);
    } finally {
      dispatch.documents.setLoading(false);
    }
  };

  const handleOutliner = () => {
    dispatch.utils.setOpenOutliner(!openOutliner);
  };

  const handleSwitch = () => {
    if (!selected && editor.getText().length > 0) {
      setSaveDoc(true);
    } else {
      handleSwitchDocument();

    }

  };

  useEffect(() => {
    if (planData?.plan) {
      if (planData?.plan?.toLowerCase() !== 'free') {
        dispatch.utils.setTopBanner(false)
        dispatch.utils.setGiftCard(false)
      }
    }

  }, [planData])

  // const handleSaveDoc = async title => {
  //   try {
  //     dispatch.documents.setLoading(true);
  //     const data = {
  //       user: user.id,
  //       title: title,
  //       body: editor.getHTML(),
  //     };
  //     editor.commands.setContent('');
  //     dispatch.documents.setSelected(null);
  //     await saveDocument(data);
  //     await getAllDocuments();
  //     handleSwitchDocument();
  //   } catch (error) {
  //     console.log(error.message);
  //   } finally {
  //     dispatch.documents.setLoading(false);
  //   }
  // };

  if (isDesktop && !bittensor) {

    const settingIcon = () => (<div
      onClick={() => {
        dispatch.utils.setOpenMobileRightMenu(true);
        dispatch.chat.setIsShowMobileSidebar(true)
      }}
      className='mobile-chat-to-doc cursor-pointer'>
      <img src={assets.settingsWhite} alt='' />
    </div>)

    return (
      <MainContainer className='mobile-home-container'>
        {topBanner && <TopBanner />}
        <Container>
          <MobileSidebar />
          {mobileScreen === "sidebar" && <NewSidebar isMobile={true} />}

          {mobileScreen === "chat" && (<>
            <S.Header className={`mobile-bg-home-color`}>
              <div className={`left_button-container ${mode === modes.image && 'padding-tb-5'}`}>
                <div
                  onClick={() => {
                    dispatch.chat.setMobileScreen('sidebar')

                    if (mode === modes.image) {
                      dispatch.imageGeneration.setMobileTab('History')
                    }
                  }}
                  className='mobile-clock-wise'>
                  <img src={assets.clockCounterClockwise} alt='' />
                </div>

                {mode === modes.image && (
                  <span className='image-query'>{chat[0]?.query}</span>
                )}
              </div>

              <div className={`flex align-items-center gap-4`}>
                {
                  mode !== modes.image ? (
                    <>
                      <div><ApplicationDropdown isMobile={true} /></div>

                      <div className='flex gap-2 align-items-center'>
                        {
                          mode === modes.chat ? (<>
                            <div
                              onClick={handleSwitch}
                              className='mobile-chat-to-doc cursor-pointer'>
                              <img src={assets.documentText} alt='' />
                            </div>

                            <div
                              onClick={handleOutliner}
                              className='mobile-chat-to-doc cursor-pointer'>
                              <img src={assets.alignLeft} alt='' />
                            </div>
                          </>) : (<>
                            {settingIcon()}
                          </>)
                        }

                      </div>
                    </>
                  ) : (
                    <>
                      {settingIcon()}
                    </>
                  )
                }
              </div>


            </S.Header>

            <Tabs>

              <div
                onClick={() => handleClick('chat')}
                className={`tab ${mode === modes.chat && 'active '} `}
              >
                <div>
                  <div className='fill'><assets.chatIconCom /></div>
                  <span>Chat</span>
                </div>
              </div>

              <div
                onClick={() => handleClick('document')}
                className={`tab ${mode === modes.document && 'active '} `}
              >
                <div>
                  <div className='stroke'><assets.docIconCom /></div>
                  <span>Doc AI</span>
                </div>
              </div>

              <div
                onClick={() => handleClick('image')}
                className={`tab ${mode === modes.image && 'active '} `}
              >
                <div className='fill'>
                  <div className='fill'><assets.craeteIconCom /></div>
                  <span>Create</span>
                </div>
              </div>

            </Tabs>



            {mode === modes.chat && (
              <Content
                signUpModal={signUpModal}
                setSignUpModal={setSignUpModal}
                subscriptionModal={subscriptionModal}
                setSubscriptionModal={setSubscriptionModal}
                mobileSidebar={mobileSidebar}
                setMobileSidebar={setMobileSidebar}
                isMobile={true}
              />
            )}

            {mode === modes.image && (
              <ImageGeneration isMobile={true} sideBar={rightSideBarRef} />
            )}
            {mode === modes.document && <DocumentView />}
          </>)}

          {
            openOutliner && <div
              className={classNames(
                'right-sidebar-col ',
                isRightSideBarOpen ? 'open' : 'close'
              )}
              ref={rightSideBarRef}
            >
              <DocumentSidebar isMobile={true} />

            </div>
          }

          {saveDoc && (
            <SaveDocument
              open={saveDoc}
              setOpen={setSaveDoc}
              onCancel={handleSwitchDocument}
              docLoading={docLoading}
            // onSave={handleSaveDoc}
            />
          )}
        </Container>
      </MainContainer>
    )
  }

  return (
    <MainContainer>
      {topBanner && <TopBanner />}
      
      <div className='topbar'>
        <Topbar />
      </div>
      <Container>

        {/* {planData?.plan?.toLowerCase() === 'free' && (
        <SalesBanner label={bannerText} showLink />
      )} */}
        {/* {planData?.plan?.toLowerCase() === 'free' && countryBanner && (
        <CountryBanner />
      )} */}
        <Fragment>
          {/* {showTut && model.value === 'picasso' && (
            <Joyride steps={tutorialSteps} callback={handleCallback} />
          )} */}
          <div
            className='home-container'
            style={{
              flexGrow: 1,
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {userData?.profile?.status === 5 && <UpgradeBanner />}
            <div
              className='row gx-0'
              style={{
                flexGrow: 1,
                overflow: 'hidden',
                flexWrap: 'nowrap'
              }}
            >
              {/* Left SideBar */}
              <div className={
                //classNames(
                `left-sidebar-col col-md-3 flex home-sidebar`
                // isLeftSideBarOpen ? 'open' : 'close'
                // )
              }
              >
                <MiniSidebar />
                <NewSidebar />
                {/* <LeftSidebar setSignUpModal={setSignUpModal} /> */}
              </div>
              {/* Content */}
              <div
                className={`content-col ${openOutliner ? 'col-md-6' : 'col-md-9'} `}
                style={{ paddingRight: openOutliner ? '0px' : '' }}
              >
                {mode === modes.image && (
                  <ImageGeneration sideBar={rightSideBarRef} />
                )}
                {mode === modes.document && <DocumentView />}

                {mode === modes.chat && (
                  <Content
                    signUpModal={signUpModal}
                    setSignUpModal={setSignUpModal}
                    subscriptionModal={subscriptionModal}
                    setSubscriptionModal={setSubscriptionModal}
                    mobileSidebar={mobileSidebar}
                    setMobileSidebar={setMobileSidebar}
                  />
                )}
              </div>
              {/* Right SideBar */}

              {
                openOutliner && <div
                  className={classNames(
                    'right-sidebar-col col-md-3 ',
                    isRightSideBarOpen ? 'open' : 'close'
                  )}
                  ref={rightSideBarRef}
                >
                  {/* {openOutliner && ( */}
                  <DocumentSidebar />
                  {/* ) */}
                  {/* // : (
                  // <RightSidebar
                  //   setSignUpModal={setSignUpModal}
                  //   setSubscriptionModal={setSubscriptionModal}
                  //   userData={userData}
                  //   loading={loading}
                  //   token={token}
                  // />
                  // ) */}
                  {/* } */}
                </div>
              }

            </div>
          </div>
          {stripeFailoure && (
            <StripeFailure open={stripeFailoure} setOpen={setStripeFailure} />
          )}
          {stripeSuccess && (
            <StripeSuccess
              userId={id}
              plan_id={plan_id}
              open={stripeSuccess}
              setOpen={setStripeSuccess}
            />
          )}
          <ShareDialog />
          {modalData && <AiImagesDetailsDialog />}
        </Fragment>
        {loader && <Loader />}
      </Container>
    </MainContainer>
  );
}

export default Home;

const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  & .added-banner{
        height: calc(-134px + 100vh) !important;
  }
  & .topbar{
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.darkgrey};
    height: 50px;
    border: 0px 0px 1px 0px solid ${({ theme }) => theme.colors.darkgrey};

  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;

  .padding-tb-5{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .image-query{
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(255, 255, 255, 1);
    width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Tabs = styled.div`

  display: flex;
  border-top: 1px solid rgba(60, 62, 69, 1);
  border-bottom: 1px solid rgba(60, 62, 69, 1);
  width: 100%;
  height: 49px;
  background-color: rgba(46, 47, 51, 1);
  padding: 6px 16px;

  & .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    width: 100%;
    gap: 10px;
    cursor: pointer;

    div{
      display: flex;
      align-items: stretch;
      gap: 10px;

      .fill{
        svg {
          width: 20px;
          height: 20px;
        }
        svg path{
          fill: rgba(105, 106, 113, 1);
        }
      }

      .stroke{
        svg {
          width: 20px;
          height: 20px;
        }
        svg path{
          stroke: rgba(105, 106, 113, 1);
        }
      }

      span{
        font-weight: 400;
        font-size: 15px;
        line-height: 22.5px;
        color: rgba(105, 106, 113, 1);
      }
    }
  }

  & .active {
    background-color: rgba(34, 146, 249, 1);
    border-radius: 8px;
    
    div {
      span{
        color: rgba(255, 255, 255, 1);
      }

      .fill{
        svg path{
          fill: rgba(255, 255, 255, 1);
        }
      }

      .stroke{
        svg path{
          stroke: rgba(255, 255, 255, 1);
        }
      }
    }
  }
`;