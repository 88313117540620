import assets from 'assets'
import FreeTag from 'container/components/FreeTag/FreeTag'
import Promps from 'container/components/Promps/Promps'
import UpgradeButton from 'container/components/UpgradeButton/UpgradeButton'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const Topbar = () => {

    const {bittensor} = useSelector(state => state.utils)

  return (
      <Container className='row'>
          <div className='title col-lg-3 col-md-3'>
              <img className='cursor-pointer' onClick={() => window.open('https://chatai.com', '_blank')} src={assets.chataiText} alt='' />
          </div>

          <div className='col-lg-5 col-md-5 flex justify-content-end'>
              <div className='searchbox'>
                  <img src={assets.searchIcon} width={12} height={12} alt='' />
                  <input placeholder='Search' />
                  <img src={assets.searchLine} alt='' width={20} height={20} />
              </div>
          </div>
          
          {
              !bittensor && (<div className='right col-lg-4'>

                  <Promps />
                  <FreeTag />

                  <UpgradeButton />

              </div>)
          }

          
    </Container>
  )
}

export default Topbar

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    & .title{
        padding-left: 20px;
    }

    & .searchbox {
        
        border-radius: 8px;
        border: 1px solid #4D4F56;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 36px;
        width: 487px;
        gap: 5px;
        padding: 5px 5px 5px 10px;

        & input {
            background-color: transparent;
            outline: none;
            border: none;
            font-weight: 300;
            font-size: 14px;
            width: 100%;
            color: #494A50;
        }
    }

    & .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        padding: 0px;
        margin: 0px;
    }

    @media only screen and (max-width: 1000px) {
        display: none;
    }
`