/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import '../../../../LeftSidebar/leftSidebar.css'
import { useSelector, useDispatch } from 'react-redux';
import Conversation from 'container/pages/Home/components/LeftSidebar/Conversation';
import { config } from 'container/data/models';
import assets from 'assets';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import { EditorContext } from 'container/context/editorContext';
import { modes } from 'app.config';
import { deleteDocument, getAllDocuments, saveDocument } from 'container/api';
import ConfirmationModel from 'container/Dialogs/ConfirmationModel/ConfirmationModel';
import FileUpload from 'container/Dialogs/FileUpload/FileUpload';
import ClickAwayListener from 'react-click-away-listener';
// import Documents from 'container/pages/Home/components/LeftSidebar/Documents';
import SaveDocument from 'container/Dialogs/SaveDocument/SaveDocument';
import ImageConversionHistory from '../CreateImage/Components/ImageConversionHistory/ImageConversionHistory';
import ModelDropdown from '../CreateImage/Components/ModelDropdown/ModelDropdown';
import ImageGenerate from '../CreateImage/Components/ImageGenerate/ImageGenerate';

dayjs.extend(utc);
dayjs.extend(relativeTime);

const RecentChats = ({ isMobile }) => {
    const dispatch = useDispatch();
    const editor = useContext(EditorContext);
    const conversations = useSelector(state => state.chat.conversations);
    const selected = useSelector(state => state.chat.selectedConversation);
    const mode = useSelector(state => state.utils.mode);
    const { isLoggedIn } = useSelector(state => state.auth);
    const [sortedConversations, setSortedConversations] = useState([]);
    const loading = useSelector(state => state.chat.responseLoading);
    const [fetchFile, setFetchFile] = useState(false)
    const model = useSelector(state => state.chat.model);
    const timeFilter = useSelector(state => state.chat.timeFilter);
    const topBanner = useSelector(state => state.utils.topBanner);

    const imageLoading = useSelector(state => state.imageGeneration.loading);
    const selectedDoc = useSelector(state => state.documents.selected);
    const documents = useSelector(state => state.documents.documents);
    const user = useSelector(state => state.auth.user);
    const { mobileTab } = useSelector(state => state.imageGeneration);

    const [shouldSaveDoc, setShouldSaveDoc] = useState(false);
    const [docLoading, setDocLoading] = useState(false);
    const [selectedVal, setSelectedVal] = useState('Most recent');
    const [open, setOpen] = useState(false)
    const [isFavClicked, setIsFavClicked] = useState(false)
    const [shouldSave, setShouldSave] = useState(false)
    const [selectedconversation, setSelectedconversation] = useState(null)
    // const [clickedDoc, setClickedDoc] = useState(null);


    //sort conversations functions
    const sortArray = async () => {

        let sortedConvoArray
        if (mode === "document") {
            sortedConvoArray = await sortArrayByLatestDate([...documents]);
        } else {
            sortedConvoArray = await sortArrayByLatestDate([...conversations]);
        }

        setSortedConversations([...sortedConvoArray]);
    };

    useEffect(() => {
        if (isLoggedIn && model) {
            handleOnLoad()
        }

    }, [isLoggedIn, timeFilter, model, mode]);

    useEffect(() => {
        if (isFavClicked) {
            handleOnLoad();
            setIsFavClicked(false);
        }
    }, [isFavClicked])


    const handleOnLoad = (id) => {
        if (mode === modes.document) {
            get_all_documents()
            // dispatch.documents.setSelected(null)
        } else {
            if (selectedVal !== 'Favorites' && selectedVal !== 'Most recent')
                setSelectedVal(model.label)

            let query = `?model=${model.value}`;
            if (timeFilter === 'recent') {
                query += `&most_recent=true`;
            } else if (timeFilter === 'favourite') {
                query += `&favourite=true`;
            }
            setTimeout(async() => {
                const data = await dispatch.chat.getAllConversations(query);

                if (id) {
                    const find = data.filter(x => x.id === id);
                    if (find.length) {
                        dispatch.chat.setSelectedImage(null)
                        dispatch.chat.setSelectedConversation(find[0])
                    }
                }

            }, 100);
        }
    }

    const get_all_documents = async (status) => {
        const { data: res } = await getAllDocuments();

        if (res.data.length > 0) {
            dispatch.documents.setDocuments(res.data);
            if (status === 'delete')
                dispatch.documents.setSelected(res.data[0]);
            
        } else {
            dispatch.documents.setDocuments([]);
            dispatch.documents.setSelected(null);
            // editor.commands.setContent("");
        }
    }


    useEffect(() => {
        if (isLoggedIn) {
            sortArray();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversations, documents]);
    const sortArrayByLatestDate = arr => {
        return arr.sort((a, b) => {
            const dateA = new Date(a.updated_at);
            const dateB = new Date(b.updated_at);

            return dateB - dateA;
        });
    };

    const handleDelete = async id => {
        // e.stopPropagation();

        if (mode === modes.document) {
            await deleteDocument(id);
            get_all_documents("delete");
        } else {
            dispatch.chat.deleteConversation(id);
            dispatch.outliner.reset();
        }
    };

    const handleSelect = conversation => {

        if (mode === modes.chat) {
            dispatch.chat.setSelectedConversation(conversation);
            dispatch.chat.setSelectedImage(null)
            dispatch.chat.setMobileScreen('chat')
        }
            
        else {
            
            if (!selectedDoc && editor.getText().length > 0) {
                setShouldSave(true);
                setSelectedconversation(conversation)
            } else {
                // dispatch.documents.updateDocument({
                //     body: editor.getHTML(),
                //     showToast: false,
                // });
                dispatch.documents.setSelected(conversation);
                dispatch.chat.setMobileScreen('chat')
                // editor.commands.setContent(conversation.body);
                // dispatch.utils.setOpenMobileChatMenu(false);
            }
            // dispatch.documents.setSelected(conversation)
        }

    };

    const getIcon = model => {
        const data = config.find(item => item.value === model);
        return (
            <img
                src={data?.image}
                alt={data?.value}
                style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '5px',
                    marginTop: '-2px',
                }}
            />
        );
    };

    const handleClick = () => {
        if (!loading && !imageLoading) {
            if (mode === modes.document) {
                if (!selectedDoc && editor.getText().length) {
                    setShouldSaveDoc(true);
                } else {
                    handleSaveDocument();
                    dispatch.outliner.reset();
                    dispatch.documents.setSelected(null);
                    editor.commands.setContent('');
                    editor.chain().focus().run();
                    setShouldSaveDoc(false);

                }
            } else {
                dispatch.chat.setSelectedConversation(null);
                dispatch.chat.setSelectedImage(null)
                dispatch.chat.setMessages([]);
                dispatch.chat.setMobileScreen('chat');
                dispatch.imageGeneration.setSelectedFilter('');
                dispatch.imageGeneration.setSelectedImage('');
                dispatch.imageGeneration.setSelectedTemplate('');
                dispatch.imageGeneration.setCustomPrompt('');
            }
        }
    };

    const handleSaveDocument = async (title) => {
        try {
            setDocLoading(true);
            if (!selectedDoc && editor.getText().length) {
                const data = {
                    user: user.id,
                    title: title || 'Untitled',
                    body: editor.getHTML(),
                };

                await saveDocument(data);
                dispatch.outliner.reset();
                dispatch.documents.setSelected(selectedconversation);
                editor.commands.setContent('');
                editor.chain().focus().run();
                dispatch.documents.getDocuments();
            } else {
                dispatch.documents.updateDocument({
                    body: editor.getHTML(),
                    showToast: false,
                });
            }
        } catch (err) {
            console.log(err.message);
        } finally {
            setShouldSave(false);
            setDocLoading(false);
        }
    };

    const handleClose = () => {
        dispatch.outliner.reset();
        dispatch.documents.setSelected(null);
        editor.commands.setContent('');
        editor.chain().focus().run();
        setShouldSaveDoc(false);
    };

    const handleSelectValue = (val, name) => {
        setSelectedVal(name);
        dispatch.chat.setTimeFilter(val);
        setOpen(false);
    };

    const handleCloseDropdown = () => {
        setOpen(false)
    }

    // const OverriteDocument = () => {
    //     if (clickedDoc) {
    //         editor.commands.setContent(clickedDoc.body);
    //         editor.chain().focus().run();
    //         dispatch.documents.setSelected(clickedDoc);
    //     }
    // };

    const isImageHistory = (isMobile && mode === modes.image) ? true : false
    return (
        <Container style={{ position: 'relative' }}>
            <div
                className={`main ${isMobile && 'mobile-main'}`}>
                {
                    isImageHistory ? <ModelDropdown activeTab={mobileTab} isMobile={isMobile} /> : (
                        <div
                            onClick={() => {
                                if (mode !== modes.document) setOpen(true)
                            }}
                            className={`left`}>
                            <span>{mode === modes.document ? 'Most recent' : selectedVal}</span>
                            <img style={{ transform: open ? 'rotate(180deg)' : '' }} src={assets.dropdownArrow} alt='' width={14} height={14} />
                        </div>)
                }

                
                
                <div
                    onClick={() => {
                        if (mode === modes.chat || mode === modes.image) handleClick()
                        else if (mode === modes.document) setFetchFile(true);
                    }}
                    className='right'>
                    <img src={assets.plusIcon} alt='' width={30} height={30} />
                </div>
            </div>

            {
                open &&
                <ClickAwayListener onClickAway={handleCloseDropdown}>
                    <Menu>
                        <div
                            className='menu-item'
                            onClick={() => handleSelectValue('', model.label)}
                        >
                            <p className='font-size-14'>Default</p>
                        </div>
                        <div
                            className='menu-item'
                            onClick={() => handleSelectValue('recent', 'Most recent')}
                        >
                            <p className='font-size-14'>Most recent</p>
                        </div>
                        <div
                            className='menu-item'
                            onClick={() => handleSelectValue('favourite', 'Favorites')}
                        >
                            <p className='font-size-14'>Favorites</p>
                        </div>
                    </Menu>
                </ClickAwayListener>
            }

            {(mobileTab === "Generate" && isImageHistory) && <ImageGenerationContainer className={`${topBanner && "img-generation-container"}`}><ImageGenerate /></ImageGenerationContainer>}



            {/* <div className='input-container'> */}
            <div
                className={`conversations__main chat-list ${isMobile && 'mobile-chat-list'}`}
            >
                {
                   isImageHistory ? (
                        <>{mobileTab === "History" && (<ImageConversionHistory /> )}</>
                    ) :  (<>
                        {sortedConversations?.map(conversation => {
                            return (
                                <Conversation
                                    key={conversation.id}
                                    conversation={conversation}
                                    handleDelete={handleDelete}
                                    loading={loading}
                                    handleSelect={handleSelect}
                                    selected={mode === modes.chat ? selected : selectedDoc}
                                    getIcon={getIcon}
                                    setIsFavClicked={setIsFavClicked}
                                    handleOnLoad={handleOnLoad}
                                />
                            );

                        })}
                    </>)
                }
                
            </div>
            {/* </div> */}

            {fetchFile && <FileUpload open={fetchFile} setOpen={setFetchFile} />}

            {shouldSaveDoc && (
                <ConfirmationModel
                    open={shouldSaveDoc}
                    setOpen={handleClose}
                    action={handleSaveDocument}
                    descripiton="If you don't save your document your changes will be lost."
                    title='Save document'
                    btnText='Save'
                    loading={docLoading}
                />
            )}
            
            {shouldSave && (
                <SaveDocument
                    open={shouldSave}
                    setOpen={setShouldSave}
                    onSave={handleSaveDocument}
                    docLoading={docLoading}
                    // onCancel={OverriteDocument}
                />
            )}
        </Container>
    )
}

export default RecentChats

const ImageGenerationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 16px;
    overflow: auto;
    height: calc(100vh - 180px);
    background-color: rgba(46, 47, 51, 1) !important;
    border-radius: 8px;

    
`

const Container = styled.div`
    
    display: flex;
    flex-direction: column;
    width: 100%;

    .img-generation-container {
        height: calc(-310px + 100vh) !important;
    }

    & .mobile-main{
        padding: 16px;
        border: none !important;
        display: flex;
        gap: 10px;
        background-color: rgba(46, 47, 51, 1) !important;
        height: 100% !important;

        & .left{
            border: 1px solid rgba(60, 62, 69, 1) !important;
            border-radius: 8px;
            height: 44px !important;
            width: 100%;
        }

        & .right{
            border-radius: 8px;
            width: 44px !important;
            height: 44px !important;
        }
    }

    & .main{
        display: flex;
        height: 55px;
        border-bottom: 1px solid #4D4F56;

         & .left {
            width: calc(100% - 55px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            padding: 0px 10px;

            & span{
                font-weight: 400;
                font-size: 13px;
                line-height: 19.5px;
                color: var(--primary);
                font-family: Poppins;
            }
        }

        & .right{
            width: 55px;
            height: 55px;
            cursor: pointer;
            background-color: var(--red);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    & .chat-list {
        overflow: auto;
        margin-top: 5px;
        /* min-height: calc(100vh - 155px); */
        & div {
            margin: 0px;
            border-radius: 0px;
        }
    }

    & .mobile-chat-list{
        background-color: rgba(32, 33, 36, 1) !important;
        padding: 16px;
        overflow: auto;
        height: calc(100vh - 150px);
        /* margin-top: 35px; */

        & .active {
            background-color: rgba(46, 47, 51, 1) !important;
            border-radius: 8px;
        }

        & .mobile-conversation{
            padding: 20px;
            border-bottom: 1px solid rgba(46, 47, 51, 1);
            height: 90.91px !important;
        }
    }

    & .input-container{
        overflow: auto;
        min-height: calc(100vh - 100px);
    }

    
`

const Menu = styled.div`
  position: absolute;
  top: 0px;
  z-index: 30;

  /* bottom: -5px; */
    transform: translate(0%, 53%);
    background-color: #2E2F33;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* right: 0px; */

  /* width: 141px; */

  & .menu-item {
    padding: 6px 13px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkgrey};
    /* background-color: ${({ theme }) => theme.colors.darkgray}; */
    cursor: pointer;
    & p {
      margin: 0px;
    }

    &:last-of-type {
      border-bottom: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &:first-of-type {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    & .row-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .menu-arrow {
        transform: rotate(-90deg);
      }
    }

    & .model-items {
      padding-inline: 0px;
    }

    & .modal-row {
      display: flex;
      align-items: center;
      gap: 5px;

      & img {
        width: 12px;
        height: 12px;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    & .panel {
      /* display: none; */
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
    }
  }

  & .menu-item:hover{
        background-color: ${({ theme }) => theme.colors.darkgray};
    }
`;
