/* eslint-disable react-hooks/exhaustive-deps */
import { imageDimensions, imageGenerationModels } from 'app.config';
import assets from 'assets';
import { createConvesation } from 'container/api';
import Checkbox from 'container/components/Checkbox/Checkbox';
import Select from 'container/components/Select/Select';
import Switch from 'container/components/Switch/Switch';
import PromptDetail from 'container/pages/Home/components/ImageGeneration/PromptDetail/PromptDetail';
import RangeInput from 'container/pages/Home/components/ImageGeneration/components/RangeInput';
import TemplateSelector from 'container/pages/Home/components/ImageGeneration/components/TemplateSelector';
import theme from 'container/theme';
import { validate } from 'container/utils/validations';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { number, object } from 'yup';

const schema = object({
    height: number().min(90).max(2800).required(),
    width: number().min(90).max(2800).required(),
});

const ImageGenerate = ({ showButton = true }) => {

    const dispatch = useDispatch();
    const [showAdvanced, setShowAdvanced] = useState(false)
    const [dSelectorValue, setDSelectorValue] = useState('');
    const { selectedFilter, grid, model, filter, prompt_strength, seed, keepSeed,
        filters, imageCount, neg_prompt, loading, width, height,
        templates, promptEdited, clickedImageGenerationButton, selectedTemplate, selectedImage, prevSelectedImage
    } = useSelector(state => state.imageGeneration);
    const [showExclude, setShowExclue] = useState(false);
    const [customDimension, setCustomDimension] = useState(true);
    const [dimensionsError, setDimensionsError] = useState('');
    const [random, setRandom] = useState(false);


    const [selectedArtist, setSelectedArtist] = useState(null)
    const [clickedOnGenerate, setClickedOnGenerate] = useState(false)

    const selectedModel = useSelector(state => state.chat.model);


    const [prevArtistText, setPrevArtistText] = useState('')
    const [prevFilterText, setPrevFilterText] = useState('')
    const { selectedConversation, timeFilter } = useSelector(state => state.chat);

    const [Promt, setPromt] = useState("")

    const artist_options = [
        {
            label: 'None',
            img: assets.newUnSelected,
            style: ''
        },
        {
            label: 'Leonardo da Vinci',
            img: assets.LeonardodaVinci,
            style: `in the style of Leonardo da Vinci.`
        },
        {
            label: 'Michelangelo Buonarroti',
            img: assets.MichelangeloBuonarroti,
            style: `with powerful, muscular bodies, inspired by Michelangelo’s Sistine Chapel.`
        },
        {
            label: 'Rembrandt van Rijn',
            img: assets.RembrandtvanRijn,
            style: `candlelit portrait of an elderly man with deep shadows and rich textures, in the style of Rembrandt.`
        },
        {
            label: 'Vincent van Gogh',
            img: assets.VincentvanGogh,
            style: `bold colors, evoking the style of Vincent van Gogh’s 'Starry Night.`
        },
        {
            label: 'Pablo Picasso',
            img: assets.PabloPicasso,
            style: `with fragmented shapes and abstract forms, inspired by Picasso.`
        },
        {
            label: 'Claude Monet',
            img: assets.ClaudeMonet,
            style: `impressionistic brushstrokes, reminiscent of Monet.`
        },
        {
            label: 'Johannes Vermeer',
            img: assets.JohannesVermeer,
            style: `featuring realistic light and delicate details, in the style of Vermeer.`
        },
        {
            label: 'Raphael Sanzio',
            img: assets.RaphaelSanzio,
            style: `with balanced figures and vibrant colors, inspired by Raphael.`
        },
        {
            label: 'Caravaggio',
            img: assets.CaravaggioMichelangeloMerisidaCaravaggio,
            style: `with intense chiaroscuro lighting, in the style of Caravaggio.`
        },
        {
            label: 'Titian (Tiziano Vecellio)',
            img: assets.TitianTizianoVecellio,
            style: `with luxurious fabrics and a regal pose, reminiscent of Titian.`
        },
        {
            label: 'Francisco Goya',
            img: assets.FranciscoGoya,
            style: `haunting scene of a mythical creature in a nightmarish landscape, inspired by Goya’s 'Black Paintings.`
        },
        {
            label: 'Diego Velázquez',
            img: assets.DiegoVelázquez,
            style: `with a grand, opulent setting and detailed textures, in the style of Velázquez.`
        },
        {
            label: 'Jan van Eyck',
            img: assets.JanvanEyck,
            style: `featuring precise textures and reflective surfaces, reminiscent of van Eyck.`
        },
        {
            label: 'Peter Paul Rubens',
            img: assets.PeterPaulRubens,
            style: `with robust, fleshy bodies and dramatic composition, inspired by Rubens.`
        },
        {
            label: 'El Greco (Domenikos Theotokopoulos)',
            img: assets.ElGrecoDomenikosTheotokopoulos,
            style: `expressive eyes, set against a turbulent sky, in the style of El Greco.`
        },
        {
            label: 'Édouard Manet',
            img: assets.ÉdouardManet,
            style: `using loose, impressionistic brushstrokes, reminiscent of Manet.`
        },
        {
            label: 'Henri Matisse',
            img: assets.HenriMatisse,
            style: `decorative style, inspired by Matisse’s 'Dance.`
        },
        {
            label: 'Paul Cézanne',
            img: assets.PaulCézanne,
            style: `featuring bold, simplified shapes and a structured composition, in the style of Cézanne.`
        },
        {
            label: 'Jackson Pollock',
            img: assets.JacksonPollock,
            style: `splattered paint in a chaotic yet harmonious composition, inspired by Pollock.`
        },
        {
            label: 'Andy Warhol',
            img: assets.AndyWarhol,
            style: `with bold colors and repetitive patterns, reminiscent of Warhol’s screen prints.`
        },
        {
            label: 'Marc Chagall',
            img: assets.MarcChagall,
            style: `in the vibrant, colorful style of Chagall.`
        },
        {
            label: 'Wassily Kandinsky',
            img: assets.WassilyKandinsky,
            style: `evoking a sense of musical rhythm, inspired by Kandinsky.`
        },
        {
            label: 'Gustav Klimt',
            img: assets.GustavKlimt,
            style: `ornate portrait of a woman adorned with intricate patterns and flowing hair, in the style of Klimt’s 'The Kiss.`
        },
        {
            label: `Georgia O'Keeffe`,
            img: assets.GeorgiaOKeeffe,
            style: `reminiscent of O’Keeffe’s floral paintings.`
        },
        // above done
        {
            label: 'Edward Hopper',
            img: assets.EdwardHopper,
            style: `inspired by Hopper’s 'Nighthawks.`
        },
        {
            label: 'Salvador Dalí',
            img: assets.SalvadorDalí,
            style: `dreamlike elements, in the style of Dalí’s 'The Persistence of Memory.`
        },
        {
            label: 'Frida Kahlo',
            img: assets.FridaKahlo,
            style: `folkloric colors, inspired by the work of Frida Kahlo.`
        },
        {
            label: 'Edgar Degas',
            img: assets.EdgarDegas,
            style: `impressionistic brushstrokes and soft pastel colors, reminiscent of Degas.`
        },
        {
            label: 'Pierre-Auguste Renoir',
            img: assets.PierreAugusteRenoir,
            style: `vibrant colors and soft brushstrokes, in the style of Renoir.`
        },
        {
            label: 'Paul Gauguin',
            img: assets.PaulGauguin,
            style: `flat areas of color, inspired by Gauguin’s Tahitian paintings.`
        },
        {
            label: 'Albrecht Dürer',
            img: assets.AlbrechtDürer,
            style: `featuring a solemn expression, in the style of Dürer.`
        },
        {
            label: 'Caspar David Friedrich',
            img: assets.CasparDavidFriedrich,
            style: `misty landscape, evoking a sense of romanticism, inspired by Friedrich.`
        },
        {
            label: 'J.M.W. Turner',
            img: assets.JMWTurner,
            style: `using loose, expressive brushstrokes, in the style of Turner.`
        },
        {
            label: 'Jean-Michel Basquiat',
            img: assets.JeanMichelBasquiat,
            style: `raw lines and vibrant colors, reminiscent of Basquiat’s street art style.`
        },
        {
            label: 'Sandro Botticelli',
            img: assets.SandroBotticelli,
            style: `set in a mythological landscape, inspired by Botticelli’s 'The Birth of Venus.`
        },
        {
            label: 'Amedeo Modigliani',
            img: assets.AmedeoModigliani,
            style: `using soft, muted colors, in the style of Modigliani.`
        },
        {
            label: 'Georges Seurat',
            img: assets.GeorgesSeurat,
            style: `inspired by Seurat’s 'A Sunday Afternoon on the Island of La Grande Jatte.`
        },
        {
            label: 'Henri de Toulouse-Lautrec',
            img: assets.HenrideToulouseLautrec,
            style: `using bold, expressive lines, reminiscent of Toulouse-Lautrec.`
        },
        {
            label: 'Joan Miró',
            img: assets.JoanMiró,
            style: `primary colors, inspired by Miró’s surrealist works.`
        },
        {
            label: 'Roy Lichtenstein',
            img: assets.RoyLichtenstein,
            style: `pop art colors, evoking the work of Lichtenstein.`
        },
        {
            label: 'Norman Rockwell',
            img: assets.NormanRockwell,
            style: `illustrative style, reminiscent of Rockwell.`
        },
        {
            label: 'Hieronymus Bosch',
            img: assets.HieronymusBosch,
            style: `otherworldly landscape with bizarre creatures, inspired by Bosch’s 'The Garden of Earthly Delights.`
        },
        {
            label: 'David Hockney',
            img: assets.DavidHockney,
            style: `in the style of Hockney’s California paintings.`
        },
        {
            label: 'Winslow Homer',
            img: assets.WinslowHomer,
            style: `with crashing waves and dramatic lighting, evoking the work of Homer.`
        },
        {
            label: 'Grant Wood',
            img: assets.GrantWood,
            style: `regionalist elements, inspired by Wood’s 'American Gothic.`
        },
        {
            label: 'Élisabeth Vigée Le Brun',
            img: assets.ÉlisabethVigéeLeBrun,
            style: `delicate features and luxurious clothing, in the style of Vigée Le Brun.`
        },
        {
            label: 'Thomas Gainsborough',
            img: assets.ThomasGainsborough,
            style: `naturalistic colors, reminiscent of Gainsborough.`
        },
        {
            label: 'John Singer Sargent',
            img: assets.JohnSingerSargent,
            style: `luxurious textures and a sophisticated pose, in the style of Sargent.`
        },
        {
            label: 'Mary Cassatt',
            img: assets.MaryCassatt,
            style: `impressionistic brushstrokes and warm colors, inspired by Cassatt.`
        },
        {
            label: 'Artemisia Gentileschi',
            img: assets.ArtemisiaGentileschi,
            style: `dynamic lighting and realistic detail, in the style of Gentileschi.`
        },

        // {
        //     label: 'Frida Kahlo',
        //     img: assets.FridaKahlo,
        //     style: `Influenced by Frida Kahlo's 'Surrealism,' with symbolic elements, vibrant colours, and introspective themes.`
        // },
        // {
        //     label: 'Henri Matisse',
        //     img: assets.HenriMatisse,
        //     style: `In the style of Henri Matisse's 'Fauvism,' with bold colours, simplified forms, and expressive brushwork`
        // },
        // {
        //     label: 'Jackson Pollock',
        //     img: assets.JacksonPollock,
        //     style: `In the style of Jackson Pollock's 'Abstract Expressionism,' featuring drips, splatters, and energetic brushwork.`
        // },
        // {
        //     label: 'Salvador Dali',
        //     img: assets.SalvadorDali,
        //     style: `Influenced by Salvador Dalí's 'Surrealism,' featuring unexpected juxtapositions, melting objects, and a dreamlike atmosphere.`
        // },


        // {
        //     label: 'Wassily Kandinsky',
        //     img: assets.WassilyKandinsky,
        //     style: `Inspired by Wassily Kandinsky's 'Abstract Art,' featuring geometric shapes, vibrant colours, and spiritual themes.`
        // },
    ]

    useEffect(() => {

        if (!clickedOnGenerate) {
            let query = Promt;

            if (selectedArtist) {
                if (!query.includes(prevArtistText))
                    query = query + ' ' + selectedArtist.style;
                else
                    query = query.replace(prevArtistText, selectedArtist.style)
            }

            if (selectedImage) {
                if (!query.includes(prevSelectedImage))
                    query = query + ' ' + selectedImage.prompt;
                else
                    query = query.replace(prevSelectedImage, selectedImage.prompt)
            }

            if (selectedFilter) {
                if (selectedFilter?.descrption?.length) {
                    if (!query.includes(prevFilterText))
                        query = query + ' and ' + selectedFilter.descrption;
                    else
                        query = query.replace(prevFilterText, selectedFilter.descrption)
                } else {
                    if (query.includes(' and ' + prevFilterText)) {
                        query = query.replace(' and ' + prevFilterText, '')
                    }
                }
            }

            // if (filterEdited && step > 2) {
            //     query = finalPrompt;
            // }
            setPromt(query?.trim());
        } else {
            setClickedOnGenerate(false)
        }

        // eslint-disable-next-line
    }, [selectedImage, selectedFilter, selectedArtist]);

    useEffect(() => {
        if (width === 0) {
            dispatch.imageGeneration.setWidth('');
        }
        if (height === 0) {
            dispatch.imageGeneration.setHeight('');
        }

        validateDimensions();
        // eslint-disable-next-line
    }, [width, height]);
    // useEffect(() => {
    //     if (model) {
    //         let query = `?model=picasso`;
    //         if (timeFilter === 'recent') {
    //             query += `&most_recent=true`;
    //         } else if (timeFilter === 'favourite') {
    //             query += `&favourite=true`;
    //         }
    //         setTimeout(() => {
    //             dispatch.chat.getAllConversations(query);
    //         }, 100);
    //     }
    //     // eslint-disable-next-line
    // }, [timeFilter, model]);

    const handleOnSelect = name => {
        const filter = filters.find(f => f.name === name);
        setPrevFilterText(selectedFilter?.descrption || filter.descrption)
        dispatch.imageGeneration.setSelectedFilter(filter);

    };

    const handleNegativePrompt = e => {
        dispatch.imageGeneration.setNegPrompt(e.currentTarget.value);
    };

    const validateDimensions = async () => {
        const errors = await validate(schema, { height, width });
        if (errors) {
            // const errorMessages = Object?.keys(errors).map(msg => errors[msg]);
            // setDimensionsError(errorMessages.join('. '));
            dispatch.imageGeneration.setDisable(true);
        } else {
            setDimensionsError('');
            dispatch.imageGeneration.setDisable(false);
        }
    };

    const handleDimensionChange = e => {
        const value = e.target.value;
        if (value === 'custom') {
            setDSelectorValue(value);
            return setCustomDimension(true);
        } else {
            setCustomDimension(false);
        }
        const dimensions = value?.split(' x ');
        dispatch.imageGeneration.setWidth(+dimensions[0]);
        dispatch.imageGeneration.setHeight(+dimensions[1]);
        setDSelectorValue(value);
    };

    const handleDimensions = e => {
        const { name, value } = e.currentTarget;
        if (name === 'width') {
            dispatch.imageGeneration.setWidth(+value);
        } else {
            dispatch.imageGeneration.setHeight(+value);
        }
    };

    useEffect(() => {

        if (clickedImageGenerationButton && Promt.trim()) {
            handlePredict()
            dispatch.imageGeneration.setClickImageGenerationButton(false)
        } else {
            if (clickedImageGenerationButton) {
                dispatch.imageGeneration.setClickImageGenerationButton(false)
            }
        }

    }, [clickedImageGenerationButton])


    const handlePredict = async () => {
        try {
            setClickedOnGenerate(true)
            dispatch.imageGeneration.setLoading(true);
            dispatch.imageGeneration.setFinalPrompt(Promt)
            let conversationId = selectedConversation?.id;
            if (!selectedConversation) {
                const { data } = await createConvesation({
                    label: Promt,
                    model: 'picasso',
                });
                conversationId = data?.payload?.id;
                let query = `?model=${selectedModel.value}`;
                if (timeFilter === 'recent') {
                    query += `&most_recent=true`;
                } else if (timeFilter === 'favourite') {
                    query += `&favourite=true`;
                }

                dispatch.chat.getAllConversations(query);
                dispatch.chat.setSelectedConversation(data.payload);
                dispatch.chat.setSelectedImage(null)
            }

            const mModel = imageGenerationModels.find(x => x.value.trim() === model.trim())
            dispatch.imageGeneration.generateImage({
                prompt: Promt,
                conversation_id: conversationId,
                steps: 50,
                width: width,
                height: height,
                images: imageCount,
                prompt_strength: prompt_strength,
                enhance_prompt: 0,
                restore_faces: 1,
                model_id: model,
                negative_prompt: neg_prompt,
                seed: seed || '',
                image_model: mModel.label,
                filter: filter
            });



            dispatch.imageGeneration.setPrevSelectedImage('')
            dispatch.chat.setMobileScreen('chat')
            dispatch.imageGeneration.setSeed('');
            setPromt('');
            setPrevArtistText('')
            setPrevFilterText('')
            setSelectedArtist(null)
            dispatch.imageGeneration.setSelectedFilter(null);
            dispatch.imageGeneration.setSelectedImage(null);
            dispatch.imageGeneration.setFinalPrompt('');
            dispatch.imageGeneration.setCustomPrompt('');
            dispatch.imageGeneration.setIsPromptDetail(false);
            dispatch.imageGeneration.setOpenImagePopup(false);


        } catch (err) {
            console.log(err.message);
        } finally {
        }
    };

    const handleTemplateSelect = val => {

        if (val.trim() === 'None') {
            dispatch.imageGeneration.setPrevSelectedImage(selectedImage?.prompt)
            dispatch.imageGeneration.setSelectedImage(templates['None'][0]);
            dispatch.imageGeneration.setSelectedTemplate(val);
            dispatch.imageGeneration.setIsPromptDetail(false);
        } else {
            dispatch.imageGeneration.setSelectedTemplate(val);
            dispatch.imageGeneration.setPromptEdited(false);
            dispatch.imageGeneration.setIsPromptDetail(true);
        }
    };

    const handleImageCount = count => {
        dispatch.imageGeneration.setImageCount(count);
    };

    const handlePromptStrength = e => {
        dispatch.imageGeneration.setPromptStrength(+e.currentTarget.value);
    };
    return (
        <Container >
            <PromptDetail />
            {/* <div className='padding-15'> */}
            <DimensionButtons className='padding-15'>
                <Button
                    className={`ba-btn ${showAdvanced ? '' : 'active'}`}
                    onClick={() => setShowAdvanced(false)}
                    id='basic'
                >
                    Basic
                </Button>
                <Button
                    className={`ba-btn ${showAdvanced ? 'active' : ''}`}
                    onClick={() => setShowAdvanced(true)}
                    id='advanced'
                >
                    Advanced
                </Button>
            </DimensionButtons>
            <Divider />

            <Content>

                <div>
                    <p className='font-size-16'>Enter Prompt</p>
                    <p className='subtitle font-size-10'>
                        You can use a single word or a full sentence about what you want to generate.
                    </p>

                    <TextArea
                        placeholder='Describe image to generate'
                        value={Promt}
                        onChange={e => setPromt(e.target.value)}
                        rows={5}
                    ></TextArea>
                </div>

                <DividerSpan>
                    <span>OR</span>
                </DividerSpan>

                <div>
                    <p className='font-size-16'>Select a template</p>
                    <p className='subtitle font-size-10'>
                        Choose to create a custom image using a text prompt or select a pre-made image template to begin.
                    </p>
                    <div className='temp-selector'>
                        <TemplateSelector
                            containerStyles={{
                                backgroundColor: theme.colors.darkgray,
                            }}
                            onSelect={handleTemplateSelect}
                            options={Object.keys(templates).map(template => ({
                                img: templates[template || 'Art'][0].example_image_url,
                                label: template,
                            }))}
                            selected={{
                                img: promptEdited
                                    ? assets.newUnSelected
                                    : selectedTemplate
                                        ? selectedTemplate === 'custom'
                                            ? assets.newUnSelected
                                            : templates[selectedTemplate || 'Art'][0].example_image_url
                                        : assets.newUnSelected,
                                label: promptEdited
                                    ? 'Custom prompt'
                                    : selectedTemplate || 'Select an option',
                            }}
                        />
                    </div>
                </div>

                <Divider />

                <div>
                    <p className='font-size-16'>Artist</p>
                    <p className='subtitle font-size-10'>
                        Select from a list of artists and copy their styles.
                    </p>
                    <div className='temp-selector'>
                        <TemplateSelector
                            containerStyles={{
                                backgroundColor: theme.colors.darkgray,
                            }}
                            onSelect={(val) => {
                                const findedArtist = artist_options.find(x => x.label.trim() === val.trim())
                                setPrevArtistText(selectedArtist?.style || findedArtist.style)
                                setSelectedArtist(findedArtist)
                            }}
                            options={artist_options}
                            selected={selectedArtist || {
                                img: assets.newUnSelected,
                                label: 'Select an option',
                            }}
                        />
                    </div>

                    <Divider />
                </div>

                <div id='step-3'>
                    <p className='font-size-16'>Filter</p>
                    <p className='subtitle font-size-10'>
                        Experiment with different filters that can be applied to your image.
                    </p>
                    <div className='temp-selector'>
                        <TemplateSelector
                            containerStyles={{
                                backgroundColor: theme.colors.darkgray,
                            }}
                            selected={{
                                img: selectedFilter?.image_url || assets.newUnSelected,
                                label: selectedFilter?.name || 'Select',
                            }}
                            onSelect={handleOnSelect}
                            options={
                                filters.length
                                    ? filters.map(filter => ({
                                        img: filter.image_url,
                                        label: filter.name,
                                    }))
                                    : []
                            }
                        />
                    </div>
                </div>

                <Divider />

                <div id='step-4'>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <p className='font-size-16'>Exclude from image</p>
                        <Switch
                            checked={showExclude}
                            onChange={e => setShowExclue(e.target.checked)}
                        />
                    </div>

                    {showExclude && (
                        <>
                            <p className='subtitle font-size-10'>
                                Describe details you don’t want in your image like color,
                                objects or a scenery.
                            </p>
                            <TextArea
                                placeholder='goldfish, pink, blurry'
                                value={neg_prompt}
                                onChange={handleNegativePrompt}
                                rows={5}
                            ></TextArea>
                        </>
                    )}
                </div>

                <Divider />



                <div id='step-5'>
                    <p className='font-size-16'>Image Dimensions</p>
                    <p className='subtitle font-size-10'>Width x Height of the image.</p>

                    <div style={{ marginBlock: '20px' }}>
                        <Select
                            value={dSelectorValue}
                            onChange={handleDimensionChange}
                            options={imageDimensions}
                        />
                    </div>

                    {customDimension && (
                        <>
                            <p className='subtitle font-size-10'>Custom dimensions</p>
                            <CustomDimensions>
                                <Input
                                    placeholder='Add width'
                                    type='number'
                                    value={width}
                                    name='width'
                                    style={{ height: '38px' }}
                                    onChange={handleDimensions}
                                    min={90}
                                    max={1024}
                                    className={dimensionsError ? 'error' : ''}
                                />
                                <Input
                                    placeholder='Add height'
                                    type='number'
                                    name='height'
                                    value={height}
                                    onChange={handleDimensions}
                                    min={90}
                                    max={1024}
                                    style={{ height: '38px' }}
                                    className={dimensionsError ? 'error' : ''}
                                />
                            </CustomDimensions>
                        </>
                    )}
                    {dimensionsError && (
                        <p className='font-size-12 error-message'>
                            Dimensions must be between 90 and 2800
                        </p>
                    )}

                </div>

                <Divider />

                <div id='step-6'>
                    <p className='font-size-16'>Number of images</p>
                    <p className='subtitle font-size-10'>
                        Select the number of images you would like to generate.
                    </p>
                    <ImageCountGrid>
                        <Button
                            className={imageCount === 1 ? 'active' : ''}
                            onClick={() => handleImageCount(1)}
                            disabled={loading}
                        >
                            1
                        </Button>
                        <Button
                            className={imageCount === 2 ? 'active' : ''}
                            onClick={() => handleImageCount(2)}
                            disabled={loading}
                        >
                            2
                        </Button>
                        <Button
                            className={imageCount === 3 ? 'active' : ''}
                            onClick={() => handleImageCount(3)}
                            disabled={loading}
                        >
                            3
                        </Button>
                        <Button
                            className={imageCount === 4 ? 'active' : ''}
                            onClick={() => handleImageCount(4)}
                            disabled={loading}
                        >
                            4
                        </Button>
                    </ImageCountGrid>
                </div>

                <Divider />

                {showAdvanced && (
                    <>
                        <Divider />
                        <p className='font-size-16'>Columns</p>
                        <p className='subtitle font-size-10'>
                            Choose number of columns to display in viewer.
                        </p>
                        <RangeInput
                            value={grid}
                            min={1}
                            max={3}
                            onChange={e => {
                                dispatch.imageGeneration.setGrid(e.target.value);
                            }}
                        />

                        <Divider />
                        <p className='font-size-16'>Prompt Guidance</p>
                        <p className='subtitle font-size-10'>
                            Higher values will make your image closer to your prompt.
                        </p>
                        <div className='select-container'>
                            <RangeInput
                                min={7}
                                max={30}
                                value={prompt_strength}
                                onChange={handlePromptStrength}
                            />
                        </div>
                        {/* <Divider />
                                <p className='font-size-16'>Model</p>
                                <p className='subtitle font-size-10'>
                                    Different AI models can produce different or better results so
                                    feel free to experiment.
                                </p>
                                <div className='select-container'>
                                    <Select
                                        options={imageGenerationModels}
                                        onChange={handleChangeModel}
                                        value={model}
                                    />
                                </div> */}
                        <Divider />
                        <div className='flex justify-content-between'>
                            <p className='font-size-16'>Seed</p>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    color: 'var(--new-bg-color)',
                                    gap: '10px'
                                }}
                            >
                                <p className='font-size-10'>Random</p>
                                <Switch
                                    checked={random}
                                    onChange={e => setRandom(e.target.checked)}
                                />
                            </div>
                        </div>
                        <p className='subtitle font-size-10'>
                            Different numbers result in new variations of your image.
                        </p>

                        <Input
                            value={seed}
                            onChange={e => dispatch.imageGeneration.setSeed(e.target.value)}
                        />

                        <div className='flex-row gap-10'>
                            <Checkbox
                                checked={keepSeed}
                                onChange={() => {
                                    dispatch.imageGeneration.setKeepSeed(!keepSeed)
                                }}
                            />
                            <p className='font-size-10'>Use same seed for the next prompt.</p>
                        </div>
                    </>
                )}
            </Content>

            {
                showButton && <GeneratePromptContainer>
                    <button
                        disabled={!Promt}
                        className={!Promt ? "disabled" : ''}
                        onClick={handlePredict} >Generate Image</button>
                </GeneratePromptContainer>
            }
            {/* </div> */}
        </Container>
    )
}

export default ImageGenerate

const DimensionButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
`;

const Button = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 10px;
  height: 26px;
  width: 100%;
  border-radius: 4px;
  padding-inline: 10px;
  cursor: pointer;

  &.active {
    background-color: ${({ theme }) => theme.colors.red};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.lightgrey};
  margin-block: 12px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    

    & .padding-15{
        padding: 15px 15px 0px 15px ;
    }

    & .ba-btn{
        font-size: 12px;
        line-height: 18px;
        height: 26px;
    }

    @media only screen and (max-width: 1000px) {
        height: 76dvh;
    }
`;

const Content = styled.div`
    padding: 15px;

    & .template-label{
        font-family: Poppins;
    }
    /* display: none; */

    /* display: flex; */
    /* flex-direction: column; */
    /* height: calc(-25px - 100vh); */
    flex-grow: 1;
    overflow: auto;

    & .temp-selector {
      margin-top: 10px;
    }

    & .gap-10{
        gap: 10px;
    }

    & p {
      margin: 0px;

      &.subtitle {
        opacity: 0.5;
        //width: 280px;
        width: 100%;
        /* z-index: 0; */
        color: rgba(217, 218, 224, 1);
        font-size: 10px;
        line-height: 15px;
      }
    }
`

const DividerSpan = styled.div`
  width: 100%;
  margin-block: 30px;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ffffff1a;

  & span {
    color: ${({ theme }) => theme.colors.primary};
    line-height: 1;
    background-color: #2E2F33;
    padding-inline: 10px;
    margin-top: -4px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
`;

const TextArea = styled.textarea`
  border: none;
  background-color: #3F4046;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  resize: none;
`;

const CustomDimensions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const Input = styled.input`
  border: none;
  background-color: #3F4046;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 10px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;

  &.error {
    border: 1px solid ${({ theme }) => theme.colors.red};
  }
`;

const ImageCountGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6px;
  margin-top: 10px;
`;

const GeneratePromptContainer = styled.div`

    padding: 10px 15px ;
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid #4D4F56;

    & button {
        background-color: #2292F9;
        color: white;
        width: 100%;
        outline: none;
        border: none;
        border-radius: 8px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }

    & .disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
`