import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { FloatingMenu } from '@tiptap/react';
import { EditorContext } from 'container/context/editorContext';
import assets from 'assets';
import icons from 'assets/editor-icons';
import { askAiOperations, documentImageGeneration } from 'container/api';
import { ClipLoader } from 'react-spinners';
import theme from 'container/theme';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';

const CustomFloatingMenu = ({ selectionRange }) => {
  const editor = useContext(EditorContext);
  const cookie = Cookies.get('chatai-auth') || 'Default cookie';
  const dispatch = useDispatch();

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const remainingPrompts = useSelector(state => state.chat.remainingPrompts);
  const planData = useSelector(state => state.subscription.planData);

  const afterClick = () => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.setStart(selectionRange.startContainer, selectionRange.startOffset);
    range.setEnd(selectionRange.endContainer, selectionRange.endOffset);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleAddText = text => {
    if (selectionRange) {
      var textToAdd = text;
      const range = selectionRange;
      range.insertNode(document.createTextNode(textToAdd));
      afterClick();
    } else {
      const html = editor.getHTML();
      editor.commands.setContent(html + `${text}`);
    }
  };

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleAddImage = async () => {
    if (planData?.plan?.toLowerCase() === 'free' && !remainingPrompts) {
      return dispatch.utils.setSubscriptionModal(true);
    }
    if (!value) {
      return toast.error(
        'Text input is required for Ask AI and Image generation operations!'
      );
    }
    try {
      setLoading(true);
      const { data } = await documentImageGeneration({
        option: 'generate_image',
        payload: {
          prompt: value,
          steps: 50,
          width: 1080,
          height: 720,
          images: 1,
          prompt_strength: 7,
          enhance_prompt: 0,
          restore_faces: 1,
          model_id: '1285ded4-b11b-4993-a491-d87cdfe6310c',
          upscaleBy: 'x1',
        },
      });

      editor
        .chain()
        .setImage({
          src: data.data,
        })
        .run();

      editor.commands.focus();
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
      setValue('');
      dispatch.chat.handleGetRemainingPromts(cookie);
    }
  };

  const handleAskAi = async () => {
    if (planData?.plan?.toLowerCase() === 'free' && !remainingPrompts) {
      return dispatch.utils.setSubscriptionModal(true);
    }
    if (!value) {
      return toast.error(
        'Text input is required for Ask AI and Image generation operations!'
      );
    }
    try {
      setLoading(true);
      const text = value;
      const { data } = await askAiOperations({
        option: 'ask_ai',
        text,
      });
      const messageStrings = data.data?.split('\n');

      let htmlstr = '';

      messageStrings?.forEach(str => (htmlstr += `<p>${str}</p>`));
      const pos = editor.state.selection.$anchor.pos;
      editor.commands.insertContentAt(pos, htmlstr);
      // const transaction = editor.state.tr.insertText(`<li>
      // <p>Helllo</p></li>`);
      // editor.view.dispatch(transaction);
      // handleAddText(data.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
      setValue('');
      dispatch.chat.handleGetRemainingPromts(cookie);
    }
  };

  const handleAiOperation = async task => {
    if (planData?.plan?.toLowerCase() === 'free' && !remainingPrompts) {
      return dispatch.utils.setSubscriptionModal(true);
    }
    try {
      if (task === 'summarize') {
        return toast.error('Can not summerize empty document');
      }
      setLoading(true);
      const text = editor.getText();
      console.log(text);
      const { data } = await askAiOperations({
        option: task,
        text,
      });

      handleAddText(data.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
      dispatch.chat.handleGetRemainingPromts(cookie);
    }
  };

  return (
    <>
      {editor && (
        <FloatingMenu
          editor={editor}
          tippyOptions={{
            placement: 'bottom-start',
          }}
        >
          <Container>
            <div className='ai-container'>
              <div
                className='icon'
                //  onClick={toggleAI}
                role='button'
              >
                <img src={assets.stars} alt='' />
              </div>
              {loading ? (
                <p
                  className='font-size-12'
                  style={{ margin: '0px', flexGrow: 1 }}
                >
                  Generating your response...
                </p>
              ) : (
                <input
                  type='text'
                  placeholder='Ask AI to edit or generate...'
                  value={value}
                  name=''
                  id=''
                  onChange={handleChange}
                />
              )}
              <div className='icon'>
                {loading ? (
                  <ClipLoader color={theme.colors.primary} size={15} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Container>
          {!loading && (
            <Menu>
              <MenuItem role='button' onClick={handleAskAi}>
                <div className='flex-row'>
                  <img src={icons.askAi} alt='' />
                  <p className='font-size-14'>Ask AI (Generate Text)</p>
                </div>
              </MenuItem>
              <MenuItem role='button' onClick={handleAddImage}>
                <div className='flex-row'>
                  <img src={icons.gallery} alt='' />
                  <p className='font-size-14'>Imagine (Generate Image)</p>
                </div>
              </MenuItem>
              <MenuItem
                role='button'
                onClick={() => handleAiOperation('summarize')}
              >
                <div className='flex-row'>
                  <img src={icons.summary} alt='' />
                  <p className='font-size-14'>Summarize</p>
                </div>
              </MenuItem>
              <MenuItem
                role='button'
                onClick={() => handleAiOperation('continue_writing')}
              >
                <div className='flex-row'>
                  <img src={icons.magicpen} alt='' />
                  <p className='font-size-14'>Continue writing</p>
                </div>
              </MenuItem>
            </Menu>
          )}
        </FloatingMenu>
      )}
    </>
  );
};

export default CustomFloatingMenu;

const Container = styled.div`
  width: fit-content;
  /* width: 400px; */
  .ai-container {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.darkbg};
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;

    & input {
      flex-grow: 1;
      font-size: 12px;
      background-color: transparent;
      outline: none;
      border: none;
      color: ${({ theme }) => theme.colors.primary};

      &::placeholder {
        color: ${({ theme }) => theme.colors.border};
      }
    }
  }
`;

const Menu = styled.div`
  width: 250px;
  padding-inline: 10px;
  margin-top: 5px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.darkbg};
`;

const MenuItem = styled.div`
  cursor: pointer;
  padding-block: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  & .flex-row {
    gap: 10px;
    & p {
      margin: 0px;
    }
  }
`;
