import assets from "assets";

export const baseURL = process.env.REACT_APP_SERVER_URL;
export const siteURL = process.env.REACT_APP_SITE_URL;
export const referral_url = process.env.REACT_APP_REFFERAL_BASE_URL;
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const googleClientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
export const couponId = process.env.REACT_APP_COUPON_ID;
export const couponCode = process.env.REACT_APP_COUPON_CODE;
export const discount_code = process.env.REACT_APP_DISCOUNT_CODE_WELCOME;
export const welcome_discount_code = process.env.REACT_APP_DISCOUNT_CODE_NORMAL;
export const valentine_discount = process.env.REACT_APP_VALENTINE_DISCOUNT;
export const abandon5 = process.env.REACT_APP_ABANDON5;
export const abandon7 = process.env.REACT_APP_ABANDON7;
export const black_friday_code =
  process.env.REACT_APP_DISCOUNT_CODE_BLACK_FRIDAY;

export const PLAN_20 = process.env.REACT_APP_PLAN_20;
export const PLAN_30 = process.env.REACT_APP_PLAN_30;
export const PLAN_40 = process.env.REACT_APP_PLAN_40;
export const PLAN_50 = process.env.REACT_APP_PLAN_50;
export const RANDOM_1 = process.env.REACT_APP_RANDOM1;

export const LATEST_RANDOM = process.env.REACT_APP_DISCOUNT_CODE;

export const questions = [
  'Hey can I know your name, so that I can keep record of who is interacting with me? 🤖',
  'And, what is your email address? 📩 It will help me save your chats, so that you can resume them later.',
  // '🎉🎉🎉\nYou are all set!, just confirm your password and get free credits',
  'Please finish the signup process to get free credits!',
];

export const picassoConfig = {
  'Famous Artists': [
    'Paint an oil painting of parrot in the style of Picasso',
    'Draw a futuristic city in the style of Da Vinci',
    'Paint the Mona Lisa in the style of Salvador Dali',
    'Imagine a sculpture of dolphins in a red ocean made by Michalengo',
    'Show the bat mobile painted by Van Gogh in the impressionist style',
  ],
  Athletes: [
    'Create a picture of Michael Jordan playing Lebron James in the NBA finals',
    'Design a picture of Serena Williams serving a tennis ball with precision.',
    'Create a picture of Lionel Messi scoring a goal against Ronaldo CR7',
    'Visualize a photo of Muhammad Ali delivering a knockout punch in a boxing match with Mike Tyson.',
  ],
  'Famous People': [
    'Imagine a picture of Leonardo Dicaprio as the Joker in 3-D',
    'Create an image of Brad Pitt as the Night King in Game of Thrones',
    'Imagine Eminem with long hair drawn in a modernist style',
    'Imagine Kim Kardashian as Cleopatra in Hyperrealism with the pyramids in the background in the desert',
  ],
  'Art Styles': [
    'Paint a picture of NYC in the style of Cubism',
    'Draw a PlayStation 5 in the style of Pop Art and Andy Warhol',
    'A tranquil Japanese garden scene, featuring a cherry blossom tree and a koi pond, in the style of traditional Japanese woodblock prints.',
    `A cityscape in the style of Van Gogh's "Starry Night," with swirling colors and bold brushstrokes.`,
    "A portrait of a person with a half-human, half-animal face, inspired by Picasso's Cubism.",
    'A whimsical, Dr. Seuss-inspired landscape, filled with quirky, imaginative creatures and plants.',
    'A still life of fruit and flowers, rendered in the intricate, realistic style of the Dutch Golden Age.',
    'A street scene with vibrant, overlapping shapes and colors, reminiscent of the Fauvist movement.',
    'A dreamy, surreal landscape inspired by Salvador Dalí, complete with melting clocks and floating objects.',
    'A geometric, abstract composition in the style of Piet Mondrian, using primary colors and straight lines.',
    'A pop art-inspired scene, featuring everyday objects and bold, contrasting colors, similar to the works of Roy Lichtenstein.',
    'A medieval-inspired illuminated manuscript page, with intricate borders, ornate lettering, and detailed illustrations.',
  ],
  'Futuristic ': [
    'synthwave new york stock echange and a sunset',
    '3D render of a floating futuristic castle in a clear sky, digital art',
    'surreal blueish monk, dodecahedron for his head, amazing details, hyperrealistic photograph, octane made of billions of intricate small houses, GODLIKE, bokeh, photography on mars, cinematic lighting',
    'Reunion of man, team, squad, cyberpunk, abstract, full hd render + 3d octane render +4k UHD + immense detail + dramatic lighting + well lit + black, purple, blue, pink, cerulean, teal, metallic colors, + fine details + octane render + 8k',
    'Lunetic dreams of a palace in the future',
    'Futuristic solarpunk eco villages with organic permaculture food forests, 3 d octane render, realistic lighting, beautiful weather, 8 k',
  ],
  Architecture: [
    'A beautiful house in tropical modernism style inside of a jungle and full of trees and plants',
    'innovative interior design of a restaurant in rural Japan, neutral wooden materials, floor-to-ceiling windows with views of nature',
    'Award-winning interior design of a modern hotel bar, playful furry furniture, warm lamp lighting exterior shot of utopian architecture building with cinematic lighting by zaha hadid and renzo piano, darek zabrocki and greg ruthkowski, alphonse mucha, simon stalenhag, cinematic, paradise, scifi, futurism, atmospheric, sunset, concept art, artstation, trending on artstation',
    'a home built as a huge bubble, all glass  windows, doors, porches, awnings, middle of SPACE, cyberpunk lights, Giant Palm Tree, Hyper Detail, 8K, HD, Octane Rendering, Unreal Engine, V-Ray, full hd',
  ],
  Funny: [
    'A cat wearing a business suit, giving a PowerPoint presentation about the benefits of catnip',
    'A llama wearing sunglasses and a Hawaiian shirt, lounging on a beach with a tropical drink',
    'A giraffe trying to fit into a compact car, with its head sticking out of the sunroof',
    `A group of aliens having a barbecue, grilling up some "Earth burgers."`,
    'A fish wearing a scuba mask and snorkel, exploring the depths of the ocean',
  ],
};

export const supportQuestions = [
  'What made you cancel your subscription?',
  'What could we have done differently?',
  'Which area/feature do you think we can improve on? ',
];

export const imageGenerationModels = [
  {
    label: 'All',
    value: 'all',
    image: assets.Image_all
  },
  {
    label: 'SDXL',
    value: '26a1a203-3a46-42cb-8cfa-f4de075907d8',
    image: assets.SDXL
  },
  {
    label: 'Stable Diffusion 2.1',
    value: 'ee88d150-4259-4b77-9d0f-090abe29f650',
    image: assets.StableDiffusion21
  },
  {
    label: 'Stable Diffusion 1.5',
    value: '8b1b897c-d66d-45a6-b8d7-8e32421d02cf',
    image: assets.StableDiffusion15
  },
  {
    label: 'Realistic Vision v4.0',
    value: '37d42ae9-5f5f-4399-b60b-014d35e762a5',
    image: assets.RealisticVisionv40
  },
  {
    label: 'Realistic Vision v2.0',
    value: 'eab32df0-de26-4b83-a908-a83f3015e971',
    image: assets.RealisticVisionv20
  },
  {
    label: 'OpenJourney v4',
    value: '1e7737d7-545e-469f-857f-e4b46eaa151d',
    image: assets.OpenJourneyV4
  },
  {
    label: 'OpenJourney v2',
    value: 'd66b1686-5e5d-43b2-a2e7-d295d679917c',
    image: assets.OpenJourneyV2
  },
  {
    label: 'OpenJourney v1',
    value: '7575ea52-3d4f-400f-9ded-09f7b1b1a5b8',
    image: assets.OpenJourneyV1
  },
  {
    label: 'Modern Disney',
    value: '8ead1e66-5722-4ff6-a13f-b5212f575321',
    image: assets.ModernDisney
  },
  {
    label: 'Future Diffusion',
    value: '1285ded4-b11b-4993-a491-d87cdfe6310c',
    image: assets.FutureDiffusion
  },
];

export const coupons = {
  // cyber_week: 'CYBERWEEK',
  // cyber_week_23: 'CYBERMONDAY23',
  // black_friday_monthly: 'BLACKFRIDAY45M',
  // black_friday_yearly: 'BLACKFRIDAY45Y',
  // country_monthly: '50OFFMONTH',
  // country_yearly: '50OFFANNUAL',
  // valentine_monthly: 'LOVEAI30',
  // valentine_yearly: 'LOVEAI30Y',
  abandon5_monthly: 'IYiFYFRE5CH',
  abandon5_yearly: 'y8QwcY2B50CH',
  abandon7_monthly: 'dmlmVaGR7CH',
  abandon7_yearly: 'I6xGS2T170CH',
  welcome_monthly: 'WELCOME7',
  welcome_yearly: 'WELCOME70',
  MONTHLYPLAN20: '20MGENIE',
  MONTHLYPLAN30: '30MGENIE',
  MONTHLYPLAN40: '40MGENIE',
  MONTHLYPLAN50: '50MGENIE',
  YEARLYPLAN20: '20YGENIE',
  YEARLYPLAN30: '30YGENIE',
  YEARLYPLAN40: '40YGENIE',
  YEARLYPLAN50: '50YGENIE',
  random_monthly: '4uKoTsgu',
  random_yearly: 'cSnONoHA',
  latest_random_monthly: 'i2khaj',
  latest_random_yearly: 'hk2nrs',
  latest_monthly: '4uKoTsgu',
  latest_yearly: 'cSnONoHA'
};

export const dynamicCoupons = [
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'PZK6PVMi',
    coupon_code: 'IUK190',
    uuid: `8c0d3f1d-28c9-4c10-9de9-52f672a09382`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'UHdMeon4',
    coupon_code: 'GRK726',
    uuid: '8c0d3f1d-28c9-4c10-9de9-52f672a09382',
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'QLHG3VXa',
    coupon_code: 'HGTD12',
    uuid: `e8eac6ec-6d2e-4a18-8786-61d4180dc3c5`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'fDFo3RUw',
    coupon_code: 'KYF314',
    uuid: `e8eac6ec-6d2e-4a18-8786-61d4180dc3c5`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'GX2SXWYU',
    coupon_code: 'KHU109',
    uuid: `04ac59dd-e39e-4468-bc4b-86aa0038d260`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'eJtSt4i9',
    coupon_code: 'DES825',
    uuid: `04ac59dd-e39e-4468-bc4b-86aa0038d260`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'N7glqzSN',
    coupon_code: 'KGH177',
    uuid: `3babc440-a290-43a4-a299-1b8cdde7f114`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: '91p5gkdJ',
    coupon_code: 'JGF248',
    uuid: `3babc440-a290-43a4-a299-1b8cdde7f114`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'RJwyHto4',
    coupon_code: 'ZDFC13',
    uuid: `e250c5ed-0ea0-4026-aa28-94d3c3d66ac6`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'eOyfAHVy',
    coupon_code: 'FDD725',
    uuid: `e250c5ed-0ea0-4026-aa28-94d3c3d66ac6`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'L4XAQor2',
    coupon_code: 'KHU146',
    uuid: `e6d9eddc-546d-4dcb-ab81-e7a5ff2fe156`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'VgGm81AW',
    coupon_code: 'FSS725',
    uuid: `e6d9eddc-546d-4dcb-ab81-e7a5ff2fe156`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'JXUcWkyb',
    coupon_code: 'FTJ648',
    uuid: `27e74503-e785-4f05-8d60-93d2e0e8b9b1`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: '9AQpSVt1',
    coupon_code: 'DRS988',
    uuid: `27e74503-e785-4f05-8d60-93d2e0e8b9b1`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'h3Hp54UA',
    coupon_code: 'HJU684',
    uuid: `fe54a1c7-7106-4a64-9e57-02895d76a17c`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'saEws1i2',
    coupon_code: 'JKG643',
    uuid: `fe54a1c7-7106-4a64-9e57-02895d76a17c`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'Zzz7Egxt',
    coupon_code: 'GYJ335',
    uuid: `f2a6a2e0-22ce-416a-80e0-7985c4d0fa9a`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'W62BGEnh',
    coupon_code: 'IUD642',
    uuid: `f2a6a2e0-22ce-416a-80e0-7985c4d0fa9a`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'Pz80cIB8',
    coupon_code: 'DTJ437',
    uuid: `38320380-a92c-4a21-ad4f-bf632bca4240`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'WKIb73zO',
    coupon_code: 'FDD924',
    uuid: `38320380-a92c-4a21-ad4f-bf632bca4240`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'EQ8FJfwT',
    coupon_code: 'NHT187',
    uuid: `0c55a0b2-3598-401f-b83f-5ca86ee6c0bc`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'SKC108',
    coupon_code: 'SKC108',
    uuid: `0c55a0b2-3598-401f-b83f-5ca86ee6c0bc`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'BUT109',
    coupon_code: 'BUT109',
    uuid: `06a2f37a-5ae9-4c94-a91d-3d447ca86ce1`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'KSC123',
    coupon_code: 'KSC123',
    uuid: `06a2f37a-5ae9-4c94-a91d-3d447ca86ce1`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'MMB100',
    coupon_code: 'MMB100',
    uuid: `bf57577f-9fee-47e6-b4f8-39b774cf1c8e`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'DLU198',
    coupon_code: 'DLU198',
    uuid: `bf57577f-9fee-47e6-b4f8-39b774cf1c8e`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'BFT187',
    coupon_code: 'BFT187',
    uuid: `e3102479-a0b7-4d6b-a283-8dc473096256`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'HDS021',
    coupon_code: 'HDS021',
    uuid: `e3102479-a0b7-4d6b-a283-8dc473096256`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'ZLO109',
    coupon_code: 'ZLO109',
    uuid: `a3ad64d8-7db0-4e7a-b1d7-c94d4dcb3293`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'CJD289',
    coupon_code: 'CJD289',
    uuid: `a3ad64d8-7db0-4e7a-b1d7-c94d4dcb3293`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'GGH109',
    coupon_code: 'GGH109',
    uuid: `252f7681-d183-4159-9c0b-79bf94c1aeab`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'HYH180',
    coupon_code: 'HYH180',
    uuid: `252f7681-d183-4159-9c0b-79bf94c1aeab`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'JYU127',
    coupon_code: 'JYU127',
    uuid: `400d9ecf-c9f7-429b-88c2-0572a3fb4540`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'FOY378',
    coupon_code: 'FOY378',
    uuid: `400d9ecf-c9f7-429b-88c2-0572a3fb4540`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'SNH128',
    coupon_code: 'SNH128',
    uuid: `f2a73e31-be5a-49fb-8de4-d343fa4ce9c1`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'SAK279',
    coupon_code: 'SAK279',
    uuid: `f2a73e31-be5a-49fb-8de4-d343fa4ce9c1`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'ERW346',
    coupon_code: 'ERW346',
    uuid: `9ceb1909-d11d-449c-87b2-a0d2e9587b1c`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'JHY179',
    coupon_code: 'JHY179',
    uuid: `9ceb1909-d11d-449c-87b2-a0d2e9587b1c`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'JHT276',
    coupon_code: 'JHT276',
    uuid: `61570136-408b-4705-a790-51ba3582b115`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'SDC231',
    coupon_code: 'SDC231',
    uuid: `61570136-408b-4705-a790-51ba3582b115`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'BVO187',
    coupon_code: 'BVO187',
    uuid: `63b8ea19-6318-47ea-955c-fe48fe38fa06`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'NLY107',
    coupon_code: 'NLY107',
    uuid: `63b8ea19-6318-47ea-955c-fe48fe38fa06`,
    plan_id: 2,
    final_price: 81
  }, 
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'UKH548',
    coupon_code: 'UKH548',
    uuid: `99fcc3ad-1139-411b-82ce-402a8b378dea`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'LZO368',
    coupon_code: 'LZO368',
    uuid: `99fcc3ad-1139-411b-82ce-402a8b378dea`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'LOU109',
    coupon_code: 'LOU109',
    uuid: `eb951c96-a993-4848-a2e7-b9a44cb1056e`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'ASH104',
    coupon_code: 'ASH104',
    uuid: `eb951c96-a993-4848-a2e7-b9a44cb1056e`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'HGY276',
    coupon_code: 'HGY276',
    uuid: `ac0c38d4-5fb6-4bba-a317-38e61eaacdc6`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'SDC236',
    coupon_code: 'SDC236',
    uuid: `ac0c38d4-5fb6-4bba-a317-38e61eaacdc6`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'VNM251',
    coupon_code: 'VNM251',
    uuid: `4b1b8aa6-414d-47f8-a85e-13444927e69c`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'MKT840',
    coupon_code: 'MKT840',
    uuid: `4b1b8aa6-414d-47f8-a85e-13444927e69c`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'BTJ094',
    coupon_code: 'BTJ094',
    uuid: `106cd883-9287-4c40-bbb6-2ae339bd5a3f`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'SDW357',
    coupon_code: 'SDW357',
    uuid: `106cd883-9287-4c40-bbb6-2ae339bd5a3f`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'KOT287',
    coupon_code: 'KOT287',
    uuid: `a1844517-2ce8-4eca-bc10-25814956829f`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'CVW277',
    coupon_code: 'CVW277',
    uuid: `a1844517-2ce8-4eca-bc10-25814956829f`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'MCB667',
    coupon_code: 'MCB667',
    uuid: `c88e21ee-b351-4461-9a8a-7754065785d5`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'SDF999',
    coupon_code: 'SDF999',
    uuid: `c88e21ee-b351-4461-9a8a-7754065785d5`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'XAH208',
    coupon_code: 'XAH208',
    uuid: `0ec176b6-e61e-4f75-a5ba-4b0ce8cfcd25`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'DSW356',
    coupon_code: 'DSW356',
    uuid: `0ec176b6-e61e-4f75-a5ba-4b0ce8cfcd25`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '10% Off (Monthly)',
    coupon_id: 'HJD367',
    coupon_code: 'HJD367',
    uuid: `e59eace5-45a2-4d93-8281-48ea6df13941`,
    plan_id: 1,
    final_price: 8.1
  },
  {
    coupon_name: '10% Off (Annually)',
    coupon_id: 'DHF367',
    coupon_code: 'DHF367',
    uuid: `e59eace5-45a2-4d93-8281-48ea6df13941`,
    plan_id: 2,
    final_price: 81
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'HqOgQxgG',
    coupon_code: 'FYJ226',
    uuid: `0aaad76a-e19d-4d77-9f16-b51c39604318`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: '2FlXidw3',
    coupon_code: 'TDT624',
    uuid: `0aaad76a-e19d-4d77-9f16-b51c39604318`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'eRThVXsn',
    coupon_code: 'FUK724',
    uuid: `13caf482-6f9c-483d-9027-d5a700c3e514`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'DbDhKofa',
    coupon_code: 'KGD853',
    uuid: `13caf482-6f9c-483d-9027-d5a700c3e514`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'NGOWDGBS',
    coupon_code: 'RJV538',
    uuid: `6f223083-2226-4f26-90d3-d08369bded8f`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'tyqm9R3U',
    coupon_code: 'GDS751',
    uuid: `6f223083-2226-4f26-90d3-d08369bded8f`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'Cq5hO07Z',
    coupon_code: 'CHG237',
    uuid: `65c53dfe-caa5-4e07-a17c-320d809d27a8`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'UI8oRWPs',
    coupon_code: 'JGF753',
    uuid: `65c53dfe-caa5-4e07-a17c-320d809d27a8`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: '7cjZF9eA',
    coupon_code: 'SRG963',
    uuid: `d6929533-e982-4b8d-b353-ec0f098e9760`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'ENZO9RMs',
    coupon_code: 'HDE753',
    uuid: `d6929533-e982-4b8d-b353-ec0f098e9760`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: '0jC19KBo',
    coupon_code: 'DJY835',
    uuid: `dc2be451-3388-4b7a-b678-da1f4fce1d2a`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'HqH9i9yP',
    coupon_code: 'GTD625',
    uuid: `dc2be451-3388-4b7a-b678-da1f4fce1d2a`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'sgLoXw4R',
    coupon_code: 'DJJ875',
    uuid: `a6808158-3bd4-46d2-9875-87898cff6a89`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'zJb8T91Z',
    coupon_code: 'OHF532',
    uuid: `a6808158-3bd4-46d2-9875-87898cff6a89`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'UOOaNr9G',
    coupon_code: 'FYD863',
    uuid: `45f16991-3f37-4a51-b294-6603c987ce27`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'dSnQaw7C',
    coupon_code: 'FDE836',
    uuid: `45f16991-3f37-4a51-b294-6603c987ce27`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'rFbDLTHL',
    coupon_code: 'DYT752',
    uuid: `496e51f9-0e25-40f6-ac37-0e7421174a73`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'vjaauTNb',
    coupon_code: 'GDD725',
    uuid: `496e51f9-0e25-40f6-ac37-0e7421174a73`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'e2PC9Tl5',
    coupon_code: 'DSY864',
    uuid: `d7dce388-ad2e-42ad-8a87-e40da73ddeff`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'JbhJKIPz',
    coupon_code: 'ESW735',
    uuid: `d7dce388-ad2e-42ad-8a87-e40da73ddeff`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'UIG108',
    coupon_code: 'UIG108',
    uuid: `916c0c16-39a7-446b-bbdf-2650a1c91103`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'OUH326',
    coupon_code: 'OUH326',
    uuid: `916c0c16-39a7-446b-bbdf-2650a1c91103`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'FIY107',
    coupon_code: 'FIY107',
    uuid: `f6d7dd18-141c-4044-9803-aa1b16ee24f8`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'XVC521',
    coupon_code: 'XVC521',
    uuid: `f6d7dd18-141c-4044-9803-aa1b16ee24f8`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'AJS278',
    coupon_code: 'AJS278',
    uuid: `622a3c60-d8d0-4419-bdc4-6719f44be3a2`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'PSE317',
    coupon_code: 'PSE317',
    uuid: `622a3c60-d8d0-4419-bdc4-6719f44be3a2`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'ASM270',
    coupon_code: 'ASM270',
    uuid: `f564ae77-5b0d-4a2c-a823-3947f2f6b1a7`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'REW431',
    coupon_code: 'REW431',
    uuid: `f564ae77-5b0d-4a2c-a823-3947f2f6b1a7`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'HTG915',
    coupon_code: 'HTG915',
    uuid: `794831a5-90c4-4915-822b-d0afb78a1ced`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'STQ513',
    coupon_code: 'STQ513',
    uuid: `794831a5-90c4-4915-822b-d0afb78a1ced`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'DYE962',
    coupon_code: 'DYE962',
    uuid: `60dd94d2-0e52-4195-b59d-45c108352e31`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'FGR641',
    coupon_code: 'FGR641',
    uuid: `60dd94d2-0e52-4195-b59d-45c108352e31`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'JYU726',
    coupon_code: 'JYU726',
    uuid: `60fb49bb-921a-4a85-93b6-e06ce8125adc`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'CGT863',
    coupon_code: 'CGT863',
    uuid: `60fb49bb-921a-4a85-93b6-e06ce8125adc`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'POI421',
    coupon_code: 'POI421',
    uuid: `2d3223d7-3112-480e-846e-5aa889bd4ecd`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'VCX541',
    coupon_code: 'VCX541',
    uuid: `2d3223d7-3112-480e-846e-5aa889bd4ecd`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'EEF412',
    coupon_code: 'EEF412',
    uuid: `0ff8fa2b-c2b7-4e11-8249-c4dc6f052744`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'KHT316',
    coupon_code: 'KHT316',
    uuid: `0ff8fa2b-c2b7-4e11-8249-c4dc6f052744`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'DDW312',
    coupon_code: 'DDW312',
    uuid: `c522435d-300d-4cda-9250-9dfd3063984b`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'SRR961',
    coupon_code: 'SRR961',
    uuid: `c522435d-300d-4cda-9250-9dfd3063984b`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'GYG531',
    coupon_code: 'GYG531',
    uuid: `5915a36f-a88e-4447-89de-b664d828bc3b`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'CXR361',
    coupon_code: 'CXR361',
    uuid: `5915a36f-a88e-4447-89de-b664d828bc3b`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'CXV542',
    coupon_code: 'CXV542',
    uuid: `df66dcb0-5c95-47e5-8493-13d1b8c99867`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'KYT972',
    coupon_code: 'KYT972',
    uuid: `df66dcb0-5c95-47e5-8493-13d1b8c99867`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'VVN721',
    coupon_code: 'VVN721',
    uuid: `ca2fd152-b312-4216-a289-cf06d976b711`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'DFH454',
    coupon_code: 'DFH454',
    uuid: `ca2fd152-b312-4216-a289-cf06d976b711`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'XDR752',
    coupon_code: 'XDR752',
    uuid: `d54aa554-8a64-4a55-8080-bffd3e7d96af`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'VGE783',
    coupon_code: 'VGE783',
    uuid: `d54aa554-8a64-4a55-8080-bffd3e7d96af`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'CXS234',
    coupon_code: 'CXS234',
    uuid: `b69832f8-cd2c-489d-8c57-caaa2443f6b4`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'GFG872',
    coupon_code: 'GFG872',
    uuid: `b69832f8-cd2c-489d-8c57-caaa2443f6b4`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'LHE441',
    coupon_code: 'LHE441',
    uuid: `a08804e0-5b46-4807-9657-7b3b7aaa4b37`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'BRF073',
    coupon_code: 'BRF073',
    uuid: `a08804e0-5b46-4807-9657-7b3b7aaa4b37`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'CVI961',
    coupon_code: 'CVI961',
    uuid: `010458cc-c330-40ce-b87a-5ef923c9eb2e`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'KGF312',
    coupon_code: 'KGF312',
    uuid: `010458cc-c330-40ce-b87a-5ef923c9eb2e`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'XEU992',
    coupon_code: 'XEU992',
    uuid: `a0f545a7-737f-431e-b9f1-0a8cbd090587`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'DSE542',
    coupon_code: 'DSE542',
    uuid: `a0f545a7-737f-431e-b9f1-0a8cbd090587`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'MNN531',
    coupon_code: 'MNN531',
    uuid: `95daeb4a-cba5-4a22-8c12-7e0109736698`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'NBU762',
    coupon_code: 'NBU762',
    uuid: `95daeb4a-cba5-4a22-8c12-7e0109736698`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '20% Off (Monthly)',
    coupon_id: 'PIW532',
    coupon_code: 'PIW532',
    uuid: `bdaa7dcf-7f37-40d1-b4f0-e7526efa824f`,
    plan_id: 1,
    final_price: 7.2
  },
  {
    coupon_name: '20% Off (Annually)',
    coupon_id: 'FDR215',
    coupon_code: 'FDR215',
    uuid: `bdaa7dcf-7f37-40d1-b4f0-e7526efa824f`,
    plan_id: 2,
    final_price: 72
  },
  {
    coupon_name: '40% Off (SUMMER Monthly)',
    coupon_id: 'ilB8pXYc',
    coupon_code: 'SUMMER40M',
    uuid: `77ae6432-c283-45f5-b20c-39038b881382`,
    plan_id: 1,
    final_price: "5.40",
    description: 'Save 40%'
  },
  {
    coupon_name: '40% Off (SUMMER Yearly)',
    coupon_id: 'FrmcsKRh',
    coupon_code: 'SUMMER40A',
    uuid: `77ae6432-c283-45f5-b20c-39038b881382`,
    plan_id: 2,
    final_price: 54,
    description: 'Save 40%'
  },
  {
    coupon_name: 'Back2School (Yearly)',
    uuid: '8c40222c-e63f-4a57-ba5a-a0a78b6ff115',
    coupon_id: 'pxHy8iFK',
    coupon_code: 'BACK2SKOOL24',
    plan_id: 2,
    final_price: 90,
    description: 'BACK 2 SCHOOL SALE Upgrade to a 1-year plan & get your Second year FREE'
  }

]

/*
  {
    coupon_name: '90% Off (Annually)',
    coupon_id: '',
    coupon_code: '',
    uuid: ``,
    plan_id: 2,
    final_price: 9
  },
*/

export const coupon_ids = {
  // CYBERWEEK: 'pqwSuqog',
  // CYBERMONDAY23: 'pqwSuqog',
  // BLACKFRIDAY45M: '1ivsN6Kz',
  // BLACKFRIDAY45Y: '3pz7DBLE',
  // '50OFFMONTH': 'Gsgs3wMm',
  // '50OFFANNUAL': 'ezu3sWgL',
  // LOVEAI30: 'dKm5YaGr',
  // LOVEAI30Y: 'nQ743l08',
  IYiFYFRE5CH: 'uC2Ze04c',
  y8QwcY2B50CH: '30KVydYY',
  dmlmVaGR7CH: 'FWQgHw7V',
  I6xGS2T170CH: 'QN9Q4hd5',
  WELCOME7: 'lr2lrfHb',
  WELCOME70: 'ud5ko89C',
  '20MGENIE': 'JdFeE2Zm',
  '30MGENIE': 'EkBPdqWS',
  '40MGENIE': 'Zo2Q1kqu',
  '50MGENIE': 'eYmHolCq',
  '20YGENIE': '79a3vpxd',
  '30YGENIE': 'A2CAFFMd',
  '40YGENIE': 'Uy9ZfHKs',
  '50YGENIE': 'ZtyjTPh0',
  '4uKoTsgu': 'i2khaj',
  cSnONoHA: 'hk2nrs',
  'i2khaj': '4uKoTsgu',
  'hk2nrs': 'cSnONoHA',
  // '4uKoTsgu': '4uKoTsgu',
  // 'cSnONoHA': 'cSnONoHA'
};

export const bannerText =
  'Valentine’s Day Sale | Save 30% off on any plan. (Ends tonight at 11:59PM)';

export const imageDimensions = [
  {
    value: 'custom',
    label: 'Custom Dimensions',
  },
  {
    value: '1080 x 1080',
    label: 'Instagram Post / LinkedIn Feed  1:1 (1080 x 1080)',
  },
  {
    value: '1080 x 1350',
    label: 'Instagram Portrait 4:5 (1080 x 1350)',
  },
  {
    value: '1080 x 1920',
    label: 'Instagram Story 9:16 (1080 x 1920)',
  },
  {
    value: '1200 x 675',
    label: 'X Post 16:9 (1200 x 675)',
  },
  {
    value: '1500 x 500',
    label: 'X Cover 3:1 (1500 x 500)',
  },
  {
    value: '1920 x 1080',
    label: 'YouTube Thumbnail 16:9 (1920 x 1080)',
  },

  {
    value: '1584 x 396',
    label: 'LinkedIn Cover Photo 5.91:1 (1584 x 396)',
  },
  {
    value: '1200 x 1200',
    label: 'Facebook Post 1:1 (1200 x 1200)',
  },
  {
    value: '1080 x 1920',
    label: 'Facebook Story 9:16 (1080 x 1920)',
  },
  {
    value: '2800 x 2100',
    label: 'Dribble Shot 4:3 (2800 x 2100)',
  },
];

export const giftOptions = [
  {
    label: '1 Month ($9.00)',
    value: '3',
  },
  {
    label: '3 Months ($27.00)',
    value: '4',
  },
  {
    label: '6 Months ($81.00)',
    value: '5',
  },
  {
    label: '1 Year ($90.00)',
    value: '6',
  },
];

export const modes = {
  chat: 'chat',
  image: 'image-generation',
  document: 'document',
};

export const translationLanguages = [
  'English',
  'Korean',
  'Chinese',
  'Japanese',
  'Spanish',
  'Russian',
  'French',
  'Portuguese',
  'German',
  'Italian',
  'Dutch',
  'Indonesian',
  'Filipino',
  'Vietnames',
];
