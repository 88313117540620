import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';

import { EditorContent } from '@tiptap/react';
import { EditorContext } from 'container/context/editorContext';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import ToolBar from './Toolbar';
import PopupMenu from './PopupMenu';
import CustomFloatingMenu from './CustomFloatingMenu';
import Subscription from '../Subscription/Subscription';
import PaymentForm from 'container/pages/Home/components/Content/PaymentForm';

const TipTapEditor = ({ title }) => {
  const [selected, setSelected] = useState('Body');
  const [selectionRange, setSelectionRange] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);

  const { selected: selectedDocument } = useSelector(state => state.documents);
  const subscriptionModal = useSelector(state => state.utils.subscriptionModal);
  const selectedPlan = useSelector(state => state.subscription.planId);
  const scroller = useRef(null);
  const { shouldUpdate } = useSelector(state => state.documents);
  const dispatch = useDispatch();

  const editor = useContext(EditorContext);

  const handleTrackSelection = () => {
    const selection = window.getSelection();

    if (selection.toString().length > 0) {
      setSelectionRange(selection.getRangeAt(0));
    } else {
      setSelectionRange(selectionRange);
    }
    setCursorPosition(selection.getRangeAt(0));
  };

  const handleClick = name => {
    switch (name) {
      case 'bold':
        editor.chain().focus().toggleBold().run();
        break;
      case 'italic':
        editor.chain().focus().toggleItalic().run();
        break;
      case 'underline':
        editor.commands.toggleUnderline();
        editor.chain().focus();
        break;
      case 'strike':
        editor.chain().focus().toggleStrike().run();
        break;
      case 'superscript':
        editor.chain().focus().toggleSuperscript().run();
        break;
      case 'subscript':
        editor.chain().focus().toggleSubscript().run();
        break;
      case 'align-left':
        editor.commands.setTextAlign('left');
        editor.chain().focus();
        break;
      case 'align-center':
        editor.commands.setTextAlign('center');
        editor.chain().focus();
        break;
      case 'order-list':
        editor.chain().focus().toggleOrderedList().run();
        break;
      case 'unorder-list':
        editor.chain().focus().toggleBulletList().run();
        break;
      case 'undo':
        editor.chain().undo().run();
        break;
      case 'redo':
        editor.chain().redo().run();
        break;
      case 'highlight':
        editor.chain().focus().toggleHighlight().run();
        break;
      case 'code':
        editor.chain().focus().toggleCode().run();
        break;
      case 'codeBlock':
        editor.chain().focus().toggleCodeBlock().run();
        break;
      default:
        break;
    }
  };

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      setSelected('Body');
    }
  };

  useEffect(() => {
    if (editor) {
      editor.chain().focus();
    }
    // eslint-disable-next-line
  }, [editor]);

  useEffect(() => {
    editor.on('selectionUpdate', () => {
      handleTrackSelection();
    });
    // eslint-disable-next-line
  }, [editor]);

  useEffect(() => {
    if (editor && selectedDocument && shouldUpdate) {
      editor.commands.setContent(selectedDocument.body);
    } else {
      dispatch.documents.setShouldUpdate(true);
    }
    // eslint-disable-next-line
  }, [selectedDocument]);

  useEffect(() => {
    if (scroller.current) {
      scroller.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedPlan, subscriptionModal]);

  return (
    <Container>
      <ToolBar
        setLink={setLink}
        handleClick={handleClick}
        selected={selected}
        setSelected={setSelected}
        title={title}
      />
      <Editor>
        <EditorContent
          editor={editor}
          autoFocus={true}
          onKeyDown={handleKeyDown}
          // onMouseUp={handleTrackSelection}
        />

        <SubscriptionContainer>
          {subscriptionModal && (
            <div>
              <Subscription containerStyle={{ padding: '0px' }} />
            </div>
          )}
          {selectedPlan && (
            <PaymentForm
              plan={selectedPlan}
              containerStyle={{ padding: '0px' }}
            />
          )}
        </SubscriptionContainer>
        <div className='scroll' ref={scroller}></div>
      </Editor>

      <PopupMenu
        handleClick={handleClick}
        setLink={setLink}
        selectionRange={selectionRange}
      />
      <CustomFloatingMenu selectionRange={cursorPosition} />
    </Container>
  );
};

export default TipTapEditor;

// onClick={() => {
//   let sel;
//   if (window.getSelection()) {
//     sel = window.getSelection();
//     if (sel.getRangeAt && sel.rangeCount) {
//       const range = sel.getRangeAt(0);
//       range.deleteContents();
//       range.insertNode(
//         document.createTextNode('This is a pasted text')
//       );
//     }
//   }
// }}

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SubscriptionContainer = styled.div`
  padding-inline: 10px;
  padding-bottom: 10px;
`;

const Editor = styled.div`
  flex-grow: 1;
  height: 0px;
  overflow: auto;
  & .tiptap {
    outline: none;
    padding: 20px 70px;
    flex-grow: 1;
  }

  @media only screen and (max-width: 1000px) {
    /* height: calc(100dvh - 103px) !important; */
     & .tiptap {
      outline: none;
      padding: 20px;
      flex-grow: 1;
    }
  }
`;
