import React, { useEffect, useCallback } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  // PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'container/components/Button';
import InputEmail from 'container/components/StripeForm/InputEmail';
import {
  makePayment,
  stripeRecord,
  upgradePlan,
  // verifyDiscountCoupon,
} from 'container/api';
import { toast } from 'react-hot-toast';
import Spinner from 'container/components/Spinner';
import TextField from 'container/components/TextField/TextField';
import theme from 'container/theme';
import { useState } from 'react';
import { debounce } from 'container/utils';
import {
  LATEST_RANDOM,
  PLAN_20,
  PLAN_30,
  PLAN_40,
  PLAN_50,
  RANDOM_1,
  abandon5,
  abandon7,
  coupon_ids,
  // black_friday_code,
  // coupon_ids,
  coupons,
  dynamicCoupons,
  welcome_discount_code,
} from 'app.config';
import ErrorModal from 'container/Dialogs/ErrorModal/ErrorModal';
import { useNavigate } from 'react-router-dom';

const FormComponent = ({ setPrice, price, setShowThanks }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const plan = useSelector(state => state.subscription.planId);
  const loading = useSelector(state => state.subscription.loading);
  const code = useSelector(state => state.subscription.couponCode);
  const planData = useSelector(state => state.subscription.planData);
  const couponCodes = useSelector(state => state.subscription.couponCode);

  const [error, setError] = useState('');

  const [couponCode, setCouponCode] = useState('');
  const [couponVerified, setCouponVerified] = useState(false);
  const [couponError, setCouponError] = useState('');

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const discountCode = params.get('discount_code') || couponCodes;

  // eslint-disable-next-line
  const handleVerifyCoupon = useCallback(
    debounce(async code => {
      try {
        
        dispatch.subscription.setLoading(true);
        if (code) {
          const isValid = Object.values(coupons).find(e => e === code);

          if (isValid) {
            setCouponError('');
            setCouponVerified(true);
            switch (isValid) {
              case coupons.welcome_monthly:
                setPrice(7);
                dispatch.subscription.setPlanId('1');
                break;
              case coupons.welcome_yearly:
                setPrice(70);
                dispatch.subscription.setPlanId('2');
                break;
              case coupons.abandon5_monthly:
                setPrice(5);
                dispatch.subscription.setPlanId('1');
                break;
              case coupons.abandon5_yearly:
                setPrice(50);
                dispatch.subscription.setPlanId('2');
                break;
              case coupons.abandon7_monthly:
                setPrice(7);
                dispatch.subscription.setPlanId('1');
                break;
              case coupons.abandon7_yearly:
                setPrice(70);
                dispatch.subscription.setPlanId('2');
                break;
              case coupons.MONTHLYPLAN20:
                setPrice(7.2);
                dispatch.subscription.setPlanId('1');
                break;
              case coupons.YEARLYPLAN20:
                setPrice(72);
                dispatch.subscription.setPlanId('2');
                break;
              case coupons.MONTHLYPLAN30:
                setPrice(6.3);
                dispatch.subscription.setPlanId('1');
                break;
              case coupons.YEARLYPLAN30:
                setPrice(63);
                dispatch.subscription.setPlanId('2');
                break;
              case coupons.MONTHLYPLAN40:
                setPrice(5.4);
                dispatch.subscription.setPlanId('1');
                break;
              case coupons.YEARLYPLAN40:
                setPrice(54);
                dispatch.subscription.setPlanId('2');
                break;
              case coupons.MONTHLYPLAN50:
                setPrice(4.5);
                dispatch.subscription.setPlanId('1');
                break;
              case coupons.YEARLYPLAN50:
                setPrice(45);
                dispatch.subscription.setPlanId('2');
                break;
              case coupons.random_monthly:
                setPrice(1);
                dispatch.subscription.setPlanId('1');
                break;
              case coupons.random_yearly:
                setPrice(12);
                dispatch.subscription.setPlanId('2');
                break;
              case coupons.latest_random_monthly:
                setPrice(8)
                dispatch.subscription.setPlanId('1');
                break;
              case coupons.latest_random_yearly:
                setPrice(78)
                dispatch.subscription.setPlanId('2');
                break;

              default:
                const findCoupon = dynamicCoupons.find(x => x.coupon_code.trim() === isValid.trim());

                if (findCoupon) {
                  setPrice(findCoupon.final_price)
                  dispatch.subscription.setPlanId(findCoupon.plan_id)
                  setCouponVerified(true);
                } else if (+plan === 1) {
                  setPrice(9);
                } else {
                  setPrice(90);
                }
            }
          } else {
            const findCoupon = dynamicCoupons.find(x => x.coupon_code.trim() === code.trim());
    
            if (findCoupon) {
              setPrice(findCoupon.final_price)
              dispatch.subscription.setPlanId(findCoupon.plan_id.toString())
              setCouponError('')
              setCouponVerified(true);
            } else {
              setCouponError('Invalid Coupon');
              if (+plan === 1) {
                setPrice(9);
              } else {
                setPrice(90);
              }
            }
            
          }
        } else {
          setCouponVerified(false);
          setCouponError('');
        }
      } catch (err) {
        setCouponVerified(false);
        dispatch.subscription.setCouponVerified(false);
        setCouponError('Invalid Coupon');
      } finally {
        dispatch.subscription.setLoading(false);
      }
    }, 500),
    []
  );

  // const [paymentRequest, setPaymentRequest] = useState(false);

  const createSubScription = async () => {
    try {
      dispatch.subscription.setLoading(true);
      
      const paymentMethod = await stripe?.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: `${user?.first_name} ${user?.last_name}`,
          email: user?.email,
        },
      });

      if (paymentMethod.error) {
        alert(paymentMethod.error.message);
        return setError('Card information provided is not valid.');
      }

      let coupon_id;

      if (coupon_ids[couponCode]) {
        coupon_id = coupon_ids[couponCode]
      } else {
        const findCoupon = dynamicCoupons.find(x => x.coupon_code === couponCode);
        if (findCoupon) {
          coupon_id = findCoupon.coupon_id
        }
      }

      const res = await makePayment({
        plan_id: findedCoupon.uuid === "8c40222c-e63f-4a57-ba5a-a0a78b6ff115" ? 11 :  plan, // monthly => 1 , yearly => 2
        name: `${user.first_name} ${user.last_name}`,
        email: user?.email,
        paymentMethod: paymentMethod?.paymentMethod?.id,
        ...(couponVerified &&
          planData?.plan?.toLowerCase() === 'free' && {
          coupon_id: coupon_id,
          coupon_code: couponCode,
        }),
      });

      if (res.data.status === 400) {
        return setOpenErrorModal(true);
      }

      const confirmPayment = await stripe?.confirmCardPayment(
        res.data.clientSecret
      );

      if (confirmPayment?.error) {
        toast.error('Something went wrong!');
      } else {
        await stripeRecord({
          subscription_id: res.data.subscriptionId,
          plan_description: +plan === 1 ? 'month' : 'year',
          amount: +plan === 1 ? 9 : 90,
        });
        await upgradePlan();

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: paymentMethod?.paymentMethod?.id,
            affiliation: 'chatai',
            value: +plan === 1 ? 9.0 : 90.0,
            currency: 'USD',
            ...(couponVerified && { coupon: couponCode }),
            items: [
              {
                item_name: `${+plan === 1 ? 'Chat-AI Plus' : 'Chat-AI Pro'}`,
                item_id: `${+plan === 1 ? 'Chat-AI Plus' : 'Chat-AI Pro'}`,
                affiliation: 'chatai',
                price: +plan === 1 ? 9.0 : 90.0,
                currency: 'USD',
                item_brand: 'Chat AI',
                item_category: `${+plan === 1 ? 'month' : 'year'}`,
                item_list_name: 'UI home page',
                index: 0,
                quantity: 1,
              },
            ],
          },
        });
        window.triggerEvent(
          +plan === 1 ? 1 : 2,
          res.data.customer.id,
          paymentMethod?.paymentMethod?.id,
          +plan === 1 ? 9 : 90
        );
        dispatch.subscription.setCouponCode('');
        dispatch.auth.handleGetUser();
        toast.success('Your subscription is successful!');
        navigate('/thank-you', {
          state: {
            planId: +plan === 1 ? 1 : 2,
            show: true,
            id: paymentMethod?.paymentMethod?.id,
            price: price,
            couponCode: couponCode,
          },
        });
        dispatch.subscription.setPlanId('');
        // setShowThanks(true);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      dispatch.subscription.setLoading(false);
    }
  };

  useEffect(() => {
    if (discountCode || code) {
      if (planData?.plan?.toLowerCase() === 'free') {
        if (+plan === 1 && discountCode === welcome_discount_code) {
          setCouponCode(coupons.welcome_monthly);
        } else if (+plan === 2 && discountCode === welcome_discount_code) {
          setCouponCode(coupons.welcome_yearly);
        } else if (+plan === 1 && discountCode === abandon5) {
          setCouponCode(coupons.abandon5_monthly);
        } else if (+plan === 2 && discountCode === abandon5) {
          setCouponCode(coupons.abandon5_yearly);
        } else if (+plan === 1 && discountCode === abandon7) {
          setCouponCode(coupons.abandon7_monthly);
        } else if (+plan === 2 && discountCode === abandon7) {
          setCouponCode(coupons.abandon7_yearly);
        } else if (+plan === 1 && discountCode === PLAN_20) {
          setCouponCode(coupons.MONTHLYPLAN20);
        } else if (+plan === 2 && discountCode === PLAN_20) {
          setCouponCode(coupons.YEARLYPLAN20);
        } else if (+plan === 1 && discountCode === PLAN_30) {
          setCouponCode(coupons.MONTHLYPLAN30);
        } else if (+plan === 2 && discountCode === PLAN_30) {
          setCouponCode(coupons.YEARLYPLAN30);
        } else if (+plan === 1 && discountCode === PLAN_40) {
          setCouponCode(coupons.MONTHLYPLAN40);
        } else if (+plan === 2 && discountCode === PLAN_40) {
          setCouponCode(coupons.YEARLYPLAN40);
        } else if (+plan === 1 && discountCode === PLAN_50) {
          setCouponCode(coupons.MONTHLYPLAN50);
        } else if (+plan === 2 && discountCode === PLAN_50) {
          setCouponCode(coupons.YEARLYPLAN50);
        } else if (+plan === 1 && discountCode === RANDOM_1) {
          setCouponCode(coupons.random_monthly);
        } else if (+plan === 2 && discountCode === RANDOM_1) {
          setCouponCode(coupons.random_yearly);
        } else if (+plan === 1 && discountCode === LATEST_RANDOM) {
          setCouponCode(coupons.latest_random_monthly);
        } else if (+plan === 2 && discountCode === LATEST_RANDOM) {
          setCouponCode(coupons.latest_random_yearly);
        } else {
          
          const findCoupon = dynamicCoupons.find(x => x.uuid.trim() === discountCode.trim() && x.plan_id === +plan);

          if (findCoupon) {
            setCouponCode(findCoupon.coupon_code)
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [discountCode, code, planData, welcome_discount_code, abandon5, abandon7]);

  useEffect(() => {
    handleVerifyCoupon(couponCode);
    // eslint-disable-next-line
  }, [couponCode]);

  // useEffect(() => {
  //   if (!stripe || !elements) {
  //     return;
  //   }
  //   const pr = stripe.paymentRequest({
  //     currency: 'usd',
  //     country: 'US',
  //     requestPayerName: true,
  //     requestPayerEmail: true,
  //     total: {
  //       label: 'Chat ai',
  //       amount: +plan === 1 ? 900 : 9000,
  //     },
  //   });
  //   pr.canMakePayment().then(result => {
  //     if (result) {
  //       // show button
  //       setPaymentRequest(pr);
  //     } else {
  //       console.log('cannot make payment');
  //     }
  //   });

  //   pr.on('paymentmethod', async e => {
  //     const { data } = await makePayment({
  //       plan_id: plan,
  //       name: `${user.first_name} ${user.last_name}`,
  //       email: user?.email,
  //       paymentMethod: e?.paymentMethod?.id,
  //     });

  //     const { error, paymentIntent } = stripe.confirmCardPayment(
  //       data.clientSecret,
  //       {
  //         payment_method: e.paymentMethod.id,
  //       },
  //       {
  //         handleActions: false,
  //       }
  //     );
  //     if (error) {
  //       e.complete('fail');
  //       return;
  //     }
  //     e.complete('success');
  //     await stripeRecord({
  //       subscription_id: data.subscriptionId,
  //       plan_description: +plan === 1 ? 'month' : 'year',
  //       amount: +plan === 1 ? 9 : 90,
  //     });
  //     await upgradePlan();
  //     window.dataLayer.push({ ecommerce: null });
  //     window.dataLayer.push({
  //       event: 'purchase',
  //       ecommerce: {
  //         transaction_id: e?.paymentMethod?.id,
  //         affiliation: 'chatai',
  //         value: +plan === 1 ? 9.0 : 90.0,
  //         currency: 'USD',
  //         items: [
  //           {
  //             item_name: `${+plan === 1 ? 'Chat-AI Plus' : 'Chat-AI Pro'}`,
  //             item_id: `${+plan === 1 ? 'Chat-AI Plus' : 'Chat-AI Pro'}`,
  //             affiliation: 'chatai',
  //             price: +plan === 1 ? 9.0 : 90.0,
  //             currency: 'USD',
  //             item_brand: 'Chat AI',
  //             item_category: `${+plan === 1 ? 'month' : 'year'}`,
  //             item_list_name: 'UI home page',
  //             index: 0,
  //             quantity: 1,
  //           },
  //         ],
  //       },
  //     });
  //     dispatch.auth.handleGetUser();
  //     toast.success('Successfully subscribed!');
  //     dispatch.subscription.setPlanId('');

  //     if (paymentIntent.status === 'requires_action') {
  //       stripe.confirmCardPayment(data.clientSecret);
  //     }
  //   });
  //   // eslint-disable-next-line
  // }, [elements, stripe]);

  const findedCoupon = dynamicCoupons.find(x => x.uuid.trim() === discountCode?.trim())
  return (
    <Form>
      <p className='font-size-18' style={{ marginBottom: '10px' }}>
        Pay with Card
      </p>
      <InputEmail
        style={{ marginBottom: '20px' }}
        readOnly
        value={user?.email}
      />
      <p className='font-size-14'>Card information</p>
      <InputContainer>
        <CardNumberElement
          options={{
            showIcon: true,
            style: {
              base: {
                backgroundColor: 'transparent',
                fontSize: '14px',
                color: 'white',
                lineHeight: '45px',
                padding: 10,
              },
            },
          }}
        />
      </InputContainer>
      <InputContainer className='expire'>
        <div style={{ flex: 1 }}>
          <CardExpiryElement
            options={{
              style: {
                base: {
                  backgroundColor: 'transparent',
                  fontSize: '14px',
                  color: 'white',
                  lineHeight: '45px',
                  padding: 10,
                },
              },
            }}
          />
        </div>
        <div className='seprator'>
          <div className='line'></div>
        </div>
        <div style={{ flex: 1, paddingLeft: '5px' }}>
          <CardCvcElement
            options={{
              style: {
                base: {
                  backgroundColor: 'transparent',
                  fontSize: '14px',
                  color: 'white',
                  lineHeight: '45px',
                  padding: 10,
                },
              },
            }}
          />
        </div>
      </InputContainer>
      {planData?.plan?.toLowerCase() === 'free' &&
        (discountCode === welcome_discount_code ||
          discountCode === abandon5 ||
          discountCode === abandon7 ||
          discountCode === PLAN_20 ||
          discountCode === PLAN_30 ||
          discountCode === PLAN_40 ||
          discountCode === PLAN_50 ||
          discountCode === RANDOM_1 ||
        discountCode === LATEST_RANDOM ||
        findedCoupon
        ) && (
          <>
            <p className='font-size-14' style={{ marginTop: '20px' }}>
              Discount coupon
            </p>
            <TextField
              style={{
                backgroundColor: theme.colors.darkgray,
                ...(couponError && {
                  border: `1px solid ${theme.colors.red}`,
                }),
                ...(couponVerified && {
                  border: `1px solid ${theme.colors.green}`,
                }),
              }}
              readOnly
              placeholder='Discount code'
              value={couponCode}
              onChange={e => setCouponCode(e.target.value)}
            />
            {couponError && (
              <p
                style={{
                  margin: '0px',
                  textAlign: 'left',
                  color: theme.colors.red,
                  marginTop: '4px',
                }}
                className='font-size-12'
              >
                {couponError}
              </p>
            )}
          </>
        )}

      {error && (
        <p
          className='font-size-12'
          style={{
            color: 'red',
            marginTop: '10px',
            textAlign: 'left',
            marginBottom: '0px',
          }}
        >
          {error}
        </p>
      )}

      <Button
        className='btn'
        text={loading ? <Spinner /> : 'Subscribe'}
        onClick={createSubScription}
        disabled={loading}
      />

      <p
        className='font-size-10'
        style={{ textAlign: 'left', margin: '0px', marginTop: '10px' }}
      >
        By confirming your subscription, you allow ChatAI.com to charge your
        card for this payment and future payments.
      </p>

      {/* {paymentRequest && (
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
          }}
        />
      )} */}
      {openErrorModal && (
        <ErrorModal open={openErrorModal} setOpen={setOpenErrorModal} />
      )}
      {openErrorModal && (
        <ErrorModal open={openErrorModal} setOpen={setOpenErrorModal} />
      )}
    </Form>
  );
};

export default FormComponent;

const Form = styled.form`
  & .btn {
    background-color: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.primary};
    width: 100%;
    margin-top: 30px;
  }

  & .font-size-18 {
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
    text-align: left;
  }

  & .font-size-14 {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primary};
    line-height: 21px;
    margin-bottom: 10px;
    text-align: left;
  }

  & .font-size-10 {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.primary};
    line-height: 21px;
    text-align: center;
    margin-top: 15px;
    padding-inline: 10px;
  }
`;

const InputContainer = styled.div`
  height: 45px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.darkgray};
  border-radius: 7px;
  padding-inline: 10px;

  & .seprator {
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-right: 10px; */

    & .line {
      width: 1px;
      height: 70%;
      background-color: #494c54;
    }
  }

  /* & .card {
    width: 100%;
    padding: 12px;
    background-color: transparent;
    & .InputElement {
      color: ${({ theme }) => theme.colors.primary}!important;
    }
  } */

  &.expire {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }
`;
