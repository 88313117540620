import React, { useMemo } from 'react';


import { useDropzone } from 'react-dropzone';

import styled from 'styled-components';
import assets from 'assets';
import CustomButton from 'container/components/CustomButton/CustomButton';
import DialogLayout from 'container/Dialogs/DialogLayout';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: 'dashed',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const focusedStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const InputFiles = ({ open, setOpen, setFile }) => {


    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
        useDropzone({
            accept: {
                'image/jpeg': [],
                'image/png': [],
                'image/jpe': [],
                'image/webp': [],
            },
            onDrop: async acceptedFiles => {
                const file = acceptedFiles[0];
                console.log(file);
                setFile(file)
                handleClose()
            },
        });



    const handleClose = () => {
        setOpen(false);
    };

    // dispatch.outliner.reset();
    // dispatch.documents.setSelected(null);
    // editor.commands.setContent('');
    // editor.chain().focus().run();
    // setShouldSaveDoc(false);

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <DialogLayout open={open}>
            <Container style={{ position: 'relative' }}>
                <div

                    {...getRootProps({ style })} className='content gap-10'>
                    <>
                        <input style={{ border: 'none !important' }} {...getInputProps()} />
                        <div className='icon'>
                            <img src={assets.cloud} alt='' />
                        </div>
                        <p className='font-size-14 text'>
                            <span style={{ color: 'var(--blue-dark)' }}>Click to upload </span>
                            <span>
                                or drag and drop <br />
                            </span>
                            <span className='text-faint'>
                                PNG, JPEG, WEBP and JPG
                            </span>
                        </p>
                        <Ortext>
                            <span>OR</span>
                        </Ortext>
                        <CustomButton label='Browse Files' className={'btn-blue'} />
                    </>

                    <img
                        src={assets.closeIcon}
                        role='button'
                        className='close'
                        alt=''
                        onClick={e => {
                            e.stopPropagation();
                            setOpen(false);
                        }}
                    />
                </div>
            </Container>
        </DialogLayout>
    );
};

export default InputFiles;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.darkgrey};
  width: 400px;
  border-radius: 16px;
  padding: 28px 0px;
  border: 1.5px dashed #4D4F56;
  overflow: hidden;

  & .scratch-text{
    font-size: 16px;
    font-weight: 400;
    line-height: 23.2px;
  }

  & .gap-10 {
    gap: 10px;
  }

  & .create-btn{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 100;
  }

  & .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: none !important;
    padding: 0px 24px !important;
    border-radius: 16px !important;
    background-color: ${({ theme }) => theme.colors.darkgrey}!important;

    & .icon {
      height: 56px;
      width: 56px;
      background-color: ${({ theme }) => theme.colors.darkbg};
      display: grid;
      place-items: center;
      border-radius: 50%;
    }

    & .text {
      text-align: center;
      max-width: 31ch;
      color: ${({ theme }) => theme.colors.primary};
      margin-block: 16px;

      & .text-faint {
        opacity: 0.7;
        font-size: 12px;
      }
    }
  }
`;

const Ortext = styled.div`
  border-top: 1px solid #4D4F56;
  text-align: center;
  width: 100%;
  position: relative;
  margin-block: 20px 24px;

  & span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.colors.darkgrey};
    padding-inline: 10px 10px;
    font-size: 12px;
    line-height: 17.4px;
    font-weight: 600;
    color: #98A2B3;
  }
`;