/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Tabs from './Components/Tabs/Tabs'
import { useDispatch } from 'react-redux'
import ModelDropdown from './Components/ModelDropdown/ModelDropdown'
import StatusDropdown from './Components/StatusDropdown/StatusDropdown'
import ImageGenerate from './Components/ImageGenerate/ImageGenerate'
import ImageConversionHistory from './Components/ImageConversionHistory/ImageConversionHistory'



const CreateImage = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('Generate')

    useEffect(() => {
        dispatch.imageGeneration.getUserImages("all");
    }, [])


    return (
        <>
            <div>
                <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <ModelDropdown activeTab={activeTab} />

            {/* Generate Tab */}
            {activeTab === "Generate" && (<>
                <ImageGenerate />
            </>)}

            {/* History Tab */}
            {activeTab === "History" && (<>
                <StatusDropdown />
                <ImageHistoryContainer>
                    <ImageConversionHistory />
                </ImageHistoryContainer>

            </>)}




        </>
    )
}

export default CreateImage

// const Container = styled.div`
//     /* display: flex;
//     flex-direction: column; */
//     /* overflow: hidden; */
//     height: 100%;

//      @media only screen and (max-width: 1000px) {
//         /* display: none; */
//         /* height: 100vh; */
//             height: calc(-41px + 100vh);

//         /* & .left-sidebar-col{
//           display: none !important;
//         } */
//     }
// `;

const ImageHistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
`