import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import assets from 'assets';
import { getCardDetails } from 'container/api';
import theme from 'container/theme';
import TopBanner from 'container/pages/TopBanner/TopBanner';

const navConfig = [
  {
    label: 'Profile',
    icon: assets.user,
    path: '/settings/profile',
    end: true,
  },
  {
    label: 'Subscription',
    icon: assets.star,
    path: '/settings/subscription',
    end: true,
  },
  // {
  //   label: 'Referrals',
  //   icon: assets.users,
  //   path: '/settings/referrals',
  // },
  {
    label: 'Invoices',
    icon: assets.invoices,
    path: '/settings/invoices',
    end: true,
  },
  {
    label: 'Billing',
    icon: assets.book,
    path: '/settings/billing',
    end: true,
  },
  {
    label: 'Send a Gift',
    icon: assets.giftsIcon,
    path: '/settings/gifts',
    end: false,
  },
];

const SettingsLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [navItems, setNavItems] = useState(navConfig);
  const [menuOpen, setMenuOpen] = useState(false);

  const card = useSelector(state => state.subscription.card);
  const user = useSelector(state => state.auth.user);
  const { topBanner } = useSelector(state => state.utils);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleGoBack = () => {
    navigate('/');
  };

  const checkUserCard = async () => {
    try {
      if (card) {
        return;
      }
      const { data } = await getCardDetails();
      if (data.status === 404) {
        setNavItems(navItems.filter(i => i.label !== 'Billing'));
        dispatch.subscription.setHasCard(false);
      } else {
        dispatch.subscription.setHasCard(true);
        dispatch.subscription.setCard(data);
      }
    } catch (err) {
      setNavItems(navItems.filter(i => i.label !== 'Billing'));
      dispatch.subscription.setHasCard(false);
    }
  };

  useEffect(() => {
    checkUserCard();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!user) {
      dispatch.auth.handleGetUser();
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      {topBanner && <TopBanner />}
      <Container>
        <TopBar>
          <IconButton onClick={handleGoBack}>
            <img src={assets.arrowLeft} alt='' />
          </IconButton>
          <p className='font-size-16 m-0 bold'>Settings</p>
          <IconButton onClick={handleToggleMenu}>
            <img src={menuOpen ? assets.close : assets.burger} alt='' />
          </IconButton>
        </TopBar>
        <MobileSideBar className={menuOpen && 'active'}>
          <IconButton
            onClick={handleToggleMenu}
            style={{
              float: 'right',
              marginBlock: '10px',
              background: theme.colors.darkgray,
            }}
          >
            <img src={menuOpen ? assets.close : assets.burger} alt='' />
          </IconButton>
          <NavigationItemsList>
            {navItems.map(navItem => (
              <NavLink
                to={navItem.path}
                end={navItem.end}
                target='_blank'
                children={({ isActive }) => (
                  <li className={isActive && 'active'}>
                    <img src={navItem.icon} alt='' />
                    <p className='font-size-15'>{navItem.label}</p>
                  </li>
                )}
                onClick={handleToggleMenu}
              />
            ))}
          </NavigationItemsList>
        </MobileSideBar>
        <SideBar>
          <div className='logo'>
            <img src={assets.logo} alt='' />
          </div>
          <BackButton role='button' onClick={handleGoBack}>
            <img src={assets.chevronLeft} alt='' />
            <p className='font-size-12 text'>Back to Dashboard</p>
          </BackButton>
          <NavigationItemsList>
            {navItems.map(navItem => (
              <NavLink
                to={navItem.path}
                end
                children={({ isActive }) => (
                  <li className={isActive && 'active'}>
                    <img src={navItem.icon} alt='' />
                    <p className='font-size-15'>{navItem.label}</p>
                  </li>
                )}
              />
            ))}
          </NavigationItemsList>
        </SideBar>
        <Main>
          <Outlet />
        </Main>
      </Container>
    </>
  );
};

export default SettingsLayout;

const Container = styled.div`
  padding: 16px 20px 24px 24px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.darkbg};
  color: #ffffff;
  gap: 22px;
`;

const SideBar = styled.div`
  width: 240px;
  position: fixed;
  height: calc(100% - 40px);
  overflow: auto;

  @media only screen and (max-width: 660px) {
    display: none;
  }
`;

const Main = styled.main`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.darkgrey};
  flex-grow: 1;
  margin-left: 262px;
  border-radius: 12px;
  padding: 30px;

  @media only screen and (max-width: 660px) {
    margin-left: 0px;
  }
  @media only screen and (max-width: 420px) {
    padding: 20px;
  }
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-block: 30px;
  cursor: pointer;
  & .text {
    opacity: 0.5;
    margin: 0px;
  }
`;

const NavigationItemsList = styled.ul`
  list-style-type: none;
  padding: 0px;
  width: 100%;

  & li {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 45px;
    cursor: pointer;
    width: 100%;
    padding-inline: 20px;
    & p {
      margin: 0px;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.primary};
    }

    &.active {
      background-color: ${({ theme }) => theme.colors.darkgrey};
      border-radius: 10px;
    }
  }
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (min-width: 660px) {
    display: none;
    height: 0px;
  }
`;

const IconButton = styled.button`
  background-color: ${({ theme }) => theme.colors.lightgrey};
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const MobileSideBar = styled.div`
  position: fixed;
  top: 0px;
  height: calc(100%);
  background-color: ${({ theme }) => theme.colors.lightgrey};
  width: calc(100% - 40px);
  padding-top: 10px;
  padding-inline: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 10;
  transform: translateX(110%);
  transition: 0.4s;

  &.active {
    transform: translate(0);
  }
  @media only screen and (min-width: 660px) {
    display: none;
  }
`;
