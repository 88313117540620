import React, { useRef, useState } from 'react';
import './content.css';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import assets from 'assets';
import { toast } from 'react-hot-toast';
import { bittensor_test_stars, feedbackOnMessage } from 'container/api';
import { Tooltip } from 'react-tooltip';
import GeneratedImage from '../ImageGeneration/components/GeneratedImage';
// import { modes } from 'app.config';
import { config } from 'container/data/models';
import ReactStars from 'react-rating-stars-component';

dayjs.extend(utc);

const Message = ({
  message,
  avatar,
  type,
  isMine,
  date,
  isSuccess,
  isRedirectLink,
  userMsg,
  id,
  isLikedMsg,
  isDislikedMsg,
  tryleap,
  tryleapRes,
  picasso,
  noMargin,
  fromPicasso,
  isMobile,
  width,
}) => {
  const text = useRef(null);

  const dispatch = useDispatch();
  const model = useSelector(state => state.chat.model);
  const [isLiked, setIsLiked] = useState(isLikedMsg || false);
  const [isDisliked, setIsDisliked] = useState(isDislikedMsg || false);

  const { grid } = useSelector(state => state.imageGeneration);
  // const { openOutliner, mode, bittensor } = useSelector(state => state.utils);
  const { openOutliner, bittensor } = useSelector(state => state.utils);
  const [ratingStars, setratingStars] = useState(0);

  const handleLikeMessage = async () => {
    try {
      setIsLiked(true);
      setIsDisliked(false);
      if (!isLiked) {
        await feedbackOnMessage({
          prompt_id: id,
          is_liked: true,
          is_disliked: null,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleDislikeMessage = async () => {
    try {
      setIsDisliked(true);
      setIsLiked(false);
      if (!isDisliked) {
        await feedbackOnMessage({
          prompt_id: id,
          is_liked: null,
          is_disliked: true,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleAddToOutliner = () => {
    if (!openOutliner) {
      dispatch.utils.setOpenOutliner(true);
    }
    dispatch.outliner.addItem(message);
  };

  const handleRenderMessage = ref => {
    if (isRedirectLink) {
      return (
        <p className='message'>
          You've ran out of your free prompts. You are being redirected to plan
          upgrade page. <Link to='/plans'>Click here</Link> if you are not
          automatically redirected.
        </p>
      );
    }
    if (type === 'img' && !tryleap) {
      return (
        <>
          <img
            src={message}
            style={{
              height: '200px',
              width: '200px',
              borderRadius: 'none !important',
              marginTop: '8px',
            }}
            alt=''
          />
          <p
            className='image-text'
            id='picasso-response'
            style={{ display: 'none' }}
          >
            {message}
          </p>
        </>
      );
    } else if (type === 'img' && tryleap) {
      return (
        <ImageGrid style={{ gridTemplateColumns: `repeat(${grid}, 1fr)` }}>
          {tryleapRes?.output && (
            <GeneratedImage
              tryleapData={tryleapRes}
              data={tryleapRes.output}
              isMobile={isMobile}
            />
          )}
        </ImageGrid>
      );
    } else {
      const messageStrings = message?.split('\n');

      return messageStrings?.map((str, index) => (
        <p className={`message ${!userMsg && 'chatbot'}`} key={index}>
          {str.length > 0 && (
            <p style={{ font: 'inherit', margin: '0px' }}>{str}</p>
          )}
          {index !== messageStrings.length - 1 && str.length > 1 && (
            <div style={{ height: '14px' }} />
          )}
        </p>
      ));
    }
  };

  const handleCopyText = () => {
    navigator.clipboard.writeText(message);
    toast.success('Text copied to clipboard');
  };

  const handleOnShare = () => {
    dispatch.shareModal.setSelectedId(id);
    dispatch.shareModal.setOpen(true);
  };

  return (
    <div
      className={`prompts ${isMine ? 'active' : ''} ${isSuccess ? 'success' : ''
        } ${!userMsg && 'system-message'}`}
      style={{ padding: picasso ? '0px' : '' }}
    >
      <div
        className={`prompts__container ${!fromPicasso ? ' width-60' : ' width-80'
          }`}
        style={{
          position: 'relative',
          ...(picasso && {
            padding: '0px',
            background: 'transparent',
            marginTop: '20px',
          }),

          ...(noMargin && {
            marginInline: '0px',
          }),
        }}
      >
        {!userMsg && !isMine && (
          <ActionButtons>
            {bittensor ? (
              <div className='stars-bittensor'>
                <ReactStars
                  count={5}
                  value={ratingStars}
                  onChange={async (rating) => {
                    setratingStars(rating)

                    await bittensor_test_stars({
                      "model": model.value,
                      "rating": rating
                    })
                    
                  }}
                  size={24}
                  isHalf={false}
                  emptyIcon={<i className='far fa-star'></i>}
                  halfIcon={<i className='fa fa-star-half-alt'></i>}
                  fullIcon={<i className='fa fa-star'></i>}
                  activeColor='#ffd700'
                />
              </div>
            ) : (
              <>
                <span id='like'>
                  <div className='icon-button' onClick={handleLikeMessage}>
                    <img
                      src={isLiked ? assets.likeFilled : assets.like}
                      width={18}
                      height={18}
                      alt=''
                    />
                  </div>
                </span>
                <Tooltip anchorSelect='#like' content='Like prompt' />
                <span id='dislike'>
                  <div className='icon-button' onClick={handleDislikeMessage}>
                    <img
                      src={isDisliked ? assets.dislikeFilled : assets.dislike}
                      alt=''
                      width={18}
                      height={18}
                    />
                  </div>
                </span>
                <Tooltip anchorSelect='#dislike' content='Unlike prompt' />
                <span id='copy'>
                  <div className='icon-button' onClick={handleCopyText}>
                    <img
                      role='button'
                      width={18}
                      height={18}
                      src={assets.newCopy}
                      alt=''
                    />
                  </div>
                </span>
                <Tooltip anchorSelect='#copy' content='Copy' />
                <span id='share'>
                  <div className='icon-button' onClick={handleOnShare}>
                    <img src={assets.share} alt='' width={18} height={18} />
                  </div>
                </span>
                <Tooltip anchorSelect='#share' content='Share prompt' />
                <span id='add-to-outliner'>
                  <div className='icon-button' onClick={handleAddToOutliner}>
                    <img
                      src={assets.newArrowRight}
                      alt=''
                      width={18}
                      height={18}
                    />
                  </div>
                </span>
                <Tooltip
                  anchorSelect='#add-to-outliner'
                  content='Add to outliner'
                />
              </>
            )}
          </ActionButtons>
        )}

        <div className='message-input' style={{ padding: 'none' }}>
          {!picasso && (
            <img
              src={avatar || config.find(x => model.label === x.label)?.image}
              style={{ borderRadius: '50%', width: '50px', height: '50px' }}
              alt='User Avatar'
            />
          )}
          <div
            className='message-details mt-1'
            style={{ flexGrow: 1 }}
            ref={text}
          >
            {!picasso && <p className='time'>{dayjs(date).format('DD MMM')}</p>}
            {handleRenderMessage()}
          </div>
        </div>
        {isMine && (
          <div className='attention'>
            <svg
              width='7'
              height='20'
              viewBox='0 0 7 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6.25 16.875C6.25 18.5981 4.84812 20 3.125 20C1.40188 20 0 18.5981 0 16.875C0 15.1519 1.40188 13.75 3.125 13.75C4.84812 13.75 6.25 15.1519 6.25 16.875ZM0.361719 0.984336L0.892969 11.6093C0.91793 12.1083 1.32973 12.5 1.8293 12.5H4.4207C4.92027 12.5 5.33207 12.1083 5.35703 11.6093L5.88828 0.984336C5.91504 0.448828 5.48813 0 4.95195 0H1.29805C0.761875 0 0.334961 0.448828 0.361719 0.984336Z'
                fill='#FF434E'
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;

const ActionButtons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
  display: flex;
  align-items: center;
  gap: 10px;

  & .stars-bittensor span {
    font-size: 17px !important;
  }

  & .icon-button {
    cursor: pointer;
  }

  @media only screen and (max-width: 1000px) {
    img {
      width: 14px !important;
      height: 14px !important;
    }
  }
`;

const ImageGrid = styled.div`
  display: grid;
  gap: 20px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr !important;
    gap: 0px !important;
  }
  @media screen and (max-width: 420px) {
    gap: 0px !important;
    grid-template-columns: 1fr !important;
  }
  @media screen and (max-width: 1000px) {
    gap: 0px !important;
    grid-template-columns: 1fr !important;
  }
`;
