import assets from 'assets'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components'
import { abandon5, abandon7, dynamicCoupons, LATEST_RANDOM, PLAN_20, PLAN_30, PLAN_40, PLAN_50, RANDOM_1, welcome_discount_code } from 'app.config';
import { ClipLoader } from 'react-spinners';
import { useSearchParams } from 'react-router-dom';

const UpgradeButton = () => {
    const dispatch = useDispatch();
    const [, setParams] = useSearchParams();
    const loading = useSelector(state => state.subscription.planLoading);
    const dLoading = useSelector(state => state.auth.dLoading);

    const user = useSelector(state => state.auth.user);
    const planLoading = useSelector(state => state.subscription.planLoading);
    const planData = useSelector(state => state.subscription.planData);

    const plan = planData?.plan
    const params = new URLSearchParams(window.location.search);
    const discountCode = params.get('discount_code');

    const handleRenderText = () => {
        if (user?.profile?.status === 2) {
            if (plan === 'month') {
                if (discountCode === welcome_discount_code) {
                    return `Upgrade for $70/yr`;
                }
                else if (discountCode === abandon5) {
                    return `Upgrade for $50/yr`;
                } else if (discountCode === abandon7) {
                    return `Upgrade for $70/yr`;
                } else if (discountCode === PLAN_20) {
                    return `Upgrade for $72/yr`;
                } else if (discountCode === PLAN_30) {
                    return `Upgrade for $63/yr`;
                } else if (discountCode === PLAN_40) {
                    return `Upgrade for $54/yr`;
                } else if (discountCode === PLAN_50) {
                    return `Upgrade for $45/yr`;
                } else if (discountCode === RANDOM_1) {
                    return `Upgrade for $12/yr`;
                } else if (discountCode === LATEST_RANDOM) {
                    return `Upgrade for $78/yr`;
                } else {
                    const findCoupon = dynamicCoupons.find(x => x.uuid.trim() === discountCode?.trim() && x.plan_id === 2);
                    if (findCoupon) {
                        return `Upgrade for $${findCoupon.final_price}/yr`;
                    } else
                        return `Upgrade for $90/yr`;
                }
            } else {
                const findCoupon = dynamicCoupons.find(x => x.uuid.trim() === discountCode?.trim() && x.plan_id === 1);
                if (findCoupon) {
                    return `Upgrade for $${findCoupon.final_price}/mo`;
                } else
                return `Manage Plan`;
            }
        } else {
            if (discountCode === welcome_discount_code) {
                return `Upgrade for $7/mo`;
            } else if (discountCode === abandon5) {
                return `Upgrade for $5/mo`;
            } else if (discountCode === abandon7) {
                return `Upgrade for $7/mo`;
            } else if (discountCode === PLAN_20) {
                return `Upgrade for $7.2/mo`;
            } else if (discountCode === PLAN_30) {
                return `Upgrade for $6.3/mo`;
            } else if (discountCode === PLAN_40) {
                return `Upgrade for $5.4/mo`;
            } else if (discountCode === PLAN_50) {
                return `Upgrade for $4.5/mo`;
            } else if (discountCode === RANDOM_1) {
                return `Upgrade for $/mo`;
            } else if (discountCode === LATEST_RANDOM) {
                return `Upgrade for $8/mo`;
            } else {
                const findCoupon = dynamicCoupons.find(x => x.uuid.trim() === discountCode?.trim() && x.plan_id === 1);
                if (findCoupon) {
                    return `Upgrade for $${findCoupon.final_price}/mo`;
                } else
                    return `Upgrade for $9/mo`;
            }
        }
    };
    return (
        <Container>
            <button
                className='account-button'
                onClick={() => {
                    if (loading || dLoading) {
                        return;
                    } else {

                        if (planData?.plan?.toLowerCase() === 'free') {
                            setParams({ discount_code: '8c40222c-e63f-4a57-ba5a-a0a78b6ff115' });
                        }

                        dispatch.utils.setSubscriptionModal(true);
                        dispatch.utils.setOpenMobileRightMenu(false);
                        dispatch.chat.setIsShowMobileSidebar(false);
                        dispatch.chat.setMobileScreen('chat')
                    }
                }}
            >
                <img src={assets.union} alt='' width={11.29} height={14.1} />
                {planLoading || dLoading ? (
                    <ClipLoader size={15} color='#ffffff' />
                ) : (<>{handleRenderText()}</>)}
            </button>
        </Container>
    )
}

export default UpgradeButton

const Container = styled.div`

& .account-button{
    padding: 10px 24px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: var(--red);
    color: white;
    border: none;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    width: 100%;
    font-family: Poppins;
}
`;