import React from 'react'
import styled from 'styled-components'
import ApplicationDropdown from './Components/ApplicationDropdown/ApplicationDropdown'
import RecentChats from './Components/RecentChats/RecentChats'
import { modes } from 'app.config';
import { useDispatch, useSelector } from 'react-redux';
import CreateImage from './Components/CreateImage/CreateImage';
// import SmallScreenMenu from '../../Content/SmallScreenMenu';
import assets from 'assets';
import Tabs from './Components/CreateImage/Components/Tabs/Tabs';
import { useSearchParams } from 'react-router-dom';

const NewSidebar = ({ isMobile }) => {
  const dispatch = useDispatch()
  const [, setParams] = useSearchParams();
  const topBanner = useSelector(state => state.utils.topBanner);
  const { bittensor } = useSelector(state => state.utils)
  const mode = useSelector(state => state.utils.mode);
  const { giftCard } = useSelector(state => state.utils);
  const { mobileTab } = useSelector(({ imageGeneration }) => imageGeneration);
  const { isShowMobileSidebar } = useSelector(state => state.chat);

  let show = false;

  if (isMobile) {
    if (!isShowMobileSidebar && giftCard) {
      show = true
    }
  } else {
    show = giftCard
  }

  return (
    <Container
      className={`${topBanner && 'banner-added '} ${isMobile && 'mobile-bg-color'}`}>
      {
        isMobile ? (<>
          <div className='mobile-portion'>
            <div
              onClick={() => dispatch.chat.setMobileScreen('chat')}
              className='name-portion cursor-pointer flex gap-2 align-items-center'>
              <div className='img'>
                <img
                  width={22}
                  height={20}
                  src={mode === modes.chat ? assets.chatIcon : mode === modes.document ? assets.docAiIcon : assets.createIcon}
                  alt='' />
              </div>
              {/* <span>{mode === modes.chat ? 'Chat' : mode === modes.document ? 'DocAI' : 'Create'}</span> */}
            </div>

            {mode === modes.image &&
              (<div>
                <Tabs activeTab={mobileTab} setActiveTab={dispatch.imageGeneration.setMobileTab} />
              </div>)}

            <div className='mobile-portion-right'>
              <div>
                {
                  mode !== modes.image && <ApplicationDropdown isMobile={isMobile} />
                }

              </div>
              <img className='cursor-pointer' onClick={() => {
                dispatch.utils.setOpenMobileRightMenu(true);
                dispatch.chat.setIsShowMobileSidebar(true)
              }} src={assets.settingsWhite} width={24} height={24} alt='' />
            </div>
          </div>
          <div><RecentChats isMobile={true} /></div>
        </>) : (<>

          {
            mode === modes.image ? (<> <CreateImage /> </>) : (<>
              <div><ApplicationDropdown /></div>
                {!bittensor && <div><RecentChats /></div>}
            </>)
          }

        </>)
      }

      {show && (<GiftContainer>
        <div className='main-container'>
          <div
            style={{
              background: `url(${assets.tree})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            className='gift-card'>
            <div className='off'>
              <img onClick={() => {
                dispatch.utils.setGiftCard(false)
              }} className='close-icon' width={16.25} height={16.25} src={assets.whiteClose} alt='' />
            </div>

            <div className='btn-container'>
              <button
                onClick={() => {
                  setParams({ discount_code: '8c40222c-e63f-4a57-ba5a-a0a78b6ff115' });
                  // setOpenGift(false)
                  dispatch.utils.setGiftCard(false)
                }}
              >
                UPGRADE NOW
              </button>
              <span>Limited time offer</span>
            </div>
          </div>
        </div>
      </GiftContainer>)}


    </Container>
  )
}

export default NewSidebar

const GiftContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    & .main-container{
      padding: 0px 10px !important;
      position: absolute;
      /* left: 0px; */
      bottom: 10px;
      width: 280px;
    }

     & .gift-card{
        z-index: 100;
        width: 100%;
        height: 310px;
        border-radius: 12px;
        background: linear-gradient(170deg, rgba(110,194,197,1) 20%, rgba(69,123,177,1) 100%);
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
        align-items: center;
        /* padding: 18px 18px 0px 18px; */
        position: relative;

        & .tree{
            position: absolute;
            right: 10px;
            bottom: 0;
            object-fit: cover;
            /* opacity: 0.5; */
        }

        & .anak{
            position: absolute;
            bottom: 10px;
            right: 0px;
        }

        & .off{
            /* border: 2px solid #FFFFFF;
            display: flex;
            gap: 15px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 30px 20px 40px 20px; */
            /* position: relative; */

            & .close-icon{
                position: absolute;
                top: 22px;
                right: 12px;
                cursor: pointer;
                /* z-index: 1px; */
            }
            
            span{
                font-family: Outfit;
                font-weight: 400;
                font-size: 12px;
                line-height: 15.12px;
                color: #FFFFFF;
            }
        }

        & .btn-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            margin-bottom: -10px;

            button{
                /* border: 1px solid #FB6A8A; */
                /* background-color: #E1B342; */
                /* background-color: #FA3762; */
                background: rgba(17, 148, 242, 1);
                border: none;
                outline: none;
                border-radius: 8px;
                font-family: Outfit;
                font-weight: 600;
                font-size: 12px;
                line-height: 11.14px;
                text-align: center;
                color: #FFFFFF;
                height: 40px;
                width: 142px;
                outline: none;
                text-transform: uppercase;
                /* z-index: 1; */
            }

            span{
                color: #FFFFFF;
                font-family: Outfit;
                font-weight: 400;
                font-size: 8px;
                line-height: 10.08px;

            }
        }
    }
`;

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: auto;
    width: calc(100% - 64px);
    height:  calc(100vh - 50px);
    background-color: #2E2F33;
    color: white;
    position: relative;
    overflow: hidden;

    

    & .mobile-portion {
      background-color: #2E2F33;
      padding: 16px 16px 0px 16px;
      display: flex;
      justify-content: space-between;

      & .mobile-portion-right{
        display: flex;
        gap: 12px;
        align-items: center;
      }
      
      & .name-portion {
        & .img{
          background-color: var(--red);
          width: 34px;
          height: 34px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        & span{
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: var(--primary);
        }
      }
    }

    @media only screen and (max-width: 1000px) {
        /* display: none; */
        height: 100vh;
        width: 100%;
            /* height: calc(-41px + 100vh); */

        & .left-sidebar-col{
          display: none !important;
        }
    }
`