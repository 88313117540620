import { createModel } from '@rematch/core';
import { modes } from 'app.config';

export const utils = createModel()({
  name: 'utils',
  state: {
    openSignUp: false,
    openGuestEmail: false,
    openSubscriptionDialog: false,
    isLeftSideBarOpen: true,
    isRightSideBarOpen: true,
    forgetPasswordState: 'send-email',
    verifyEmail: 'create-account',
    subscription_id: '',
    loading: false,
    subscriptionModal: false,
    openControls: false,
    openMobileRightMenu: false,
    openMobileChatMenu: false,
    discountUser: false,
    openOutliner: false,
    mode: modes.chat,
    loader: false,
    openAnalysis: false,
    giftCard: true,
    topBanner: true,
    bittensor: false,
    isBittensorLogin: true,
    bitteensorData: []
  },
  reducers: {
    setIsBittensorLogin(state, payload) {
      state.isBittensorLogin = payload;
    },
    setBitteensorData(state, payload) {
      state.bitteensorData = payload;
    },
    setBittensor(state, payload) {
      state.bittensor = payload;
    },
    setOpenSignUp(state, payload) {
      state.openSignUp = payload;
    },
    setGiftCard(state, payload) {
      state.giftCard = payload;
    },
    setTopBanner(state, payload) {
      state.topBanner = payload;
    },
    setOpenAnalysis(state, payload) {
      state.openAnalysis = payload;
    },
    setOpenGuestEmail(state, payload) {
      state.openGuestEmail = payload;
    },
    setOpenSubscriptionDialog(state, payload) {
      state.openSubscriptionDialog = payload;
    },
    setLeftSideBar(state, payload) {
      state.isLeftSideBarOpen = payload;
    },
    setRightSideBar(state, payload) {
      state.isRightSideBarOpen = payload;
    },
    setForgetPassword(state, payload) {
      state.forgetPasswordState = payload;
    },
    setVerifyEmail(state, payload) {
      state.verifyEmail = payload;
    },
    setSubscriptionId(state, payload) {
      state.subscription_id = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setSubscriptionModal(state, payload) {
      state.subscriptionModal = payload;
    },
    setOpenControls(state, payload) {
      state.openControls = payload;
    },
    setOpenMobileRightMenu(state, payload) {
      state.openMobileRightMenu = payload;
    },
    setOpenMobileChatMenu(state, payload) {
      state.openMobileChatMenu = payload;
    },
    setDiscountUser(state, payload) {
      state.discountUser = payload;
    },
    setOpenOutliner(state, payload) {
      state.openOutliner = payload;
    },
    setMode(state, payload) {
      state.mode = payload;
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
  },
});
