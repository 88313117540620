import SettingsLayout from 'container/Layout/SettingsLayout';
import Subscription from 'container/pages/Settings/Subscription/Subscription';
import UserRestriction from 'container/hoc/UserRestriction';
import ForgetPassword from 'container/pages/ForgetPassword/ForgetPassword';
import Home from 'container/pages/Home/Home';
import Billing from 'container/pages/Settings/Billing/Billing';
import Invoices from 'container/pages/Settings/Invoices/Invoices';
import Profile from 'container/pages/Settings/Profile/Profile';
import SharePrompt from 'container/pages/SharePrompt/SharePrompt';
import SignIn from 'container/pages/SignIn/SignIn';
import SignUp from 'container/pages/SignUp/SignUp';
import ThankYou from 'container/pages/ThankYou/ThankYou';
import VerifyEmail from 'container/pages/VerifyEmail/VerifyEmail';
import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';
import Gifts from 'container/pages/Settings/Gifts/Gifts';
import P1Website from 'container/pages/P1_Website/P1_Website';
import Bittensor from 'container/pages/bittensor/bittensor';
// import GiftHistory from 'container/pages/Settings/GiftHistory/GiftHistory';

const Index = () => {
  return (
    <Routes>
      <Route path='/' element={<UserRestriction comp={<Home />} />} />
      <Route path='/home' element={<UserRestriction comp={<Home />} />} />

      <Route path='/sign-up' element={<SignUp />} />
      <Route path='/sign-in' element={<SignIn />} />
      <Route path='/bittensor_testing' element={<Bittensor />} />
      <Route path='/verify-email' element={<VerifyEmail />} />
      <Route path='/forget-password' element={<ForgetPassword />} />
      <Route path='/thank-you' element={<ThankYou />} />
      <Route path='/p1' element={<P1Website />} />
      <Route
        path='/settings'
        element={<UserRestriction comp={<SettingsLayout />} />}
      >
        <Route index path='profile' element={<Profile />} />
        <Route path='subscription' element={<Subscription />} />
        <Route path='invoices' element={<Invoices />} />
        <Route path='billing' element={<Billing />} />
        <Route path='gifts' element={<Gifts />} />
        {/* <Route path='gifts/history' element={<GiftHistory />} /> */}
      </Route>
      <Route path='/share/:id' element={<SharePrompt />} />
      <Route path='*' element={<Navigate to='/' replace={true} />} />
    </Routes>
  );
};

export default Index;
